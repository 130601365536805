// Menu item styling
@button-pressed-box-shadow: inset 0 1px 3px @colorRedDark;

.menu-item-active() {
  background-color: @colorRed;
  color: @colorWhite;
}
.menu-item-hover() {
  background-color: @colorRed6;
  color: @colorWhite;
}
.menu-item-pressed() {
  background-color: @colorRed7;
  color: @colorWhite;
  box-shadow: @button-pressed-box-shadow;
}

.dark-menu-item-active() {
  background-color: @colorGreyDark2;
  color: @colorGreyBluish7_4;
}
.dark-menu-item-hover() {
  background-color: @colorGreyDark2;
  color: @colorGreyBluish7_4;
}
.dark-menu-item-pressed() {
  background-color: @colorGreyDark2;
  color: @colorGreyBluish7_4;
}

// Card styling
.list-card() {
  display: flex;
  border-radius: @borderRadiusXs;
  .space-between(@spaceNorm, vertical);

  .odd() {
    background-color: @colorWhite;
    box-shadow: 1px 1px 6px @colorGrey8_1;
  }
  .even() {
    background-color: @colorGrey10;
    box-shadow:
      0 -2px 0 @colorWhite,
      0 1px 0 rgba(@colorGreyBluish, 0.2),
      1px 1px 6px @colorGrey8_1;
  }

  &:nth-child(odd) {
    .odd();
  }
  &:nth-child(even) {
    .even();
  }
  &.odd-index {
    .odd();
  }
  &.even-index {
    .even();
  }

  &.active {
    border: 1px solid @colorBlue2;
  }
}
.list-card-hover() {
  background-color: @colorWhite6;
}
.list-card-active() {
  background-color: @colorGrey9_4;
}
