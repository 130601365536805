.color-highlight {
  color: @colorRed !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.fixed-full-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.absolute-full {
  .absolute(0);
}

.absolute-middle-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-middle-to-right {
  position: absolute;
  top: 50%;
  right: -@spaceMd;
  transform: translate(100%, -50%);
}

.absolute-middle-next-to-right {
  position: absolute;
  top: 50%;
  right: @spaceMd;
  transform: translate(0, -50%);
}

.absolute-middle-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@spaces: {
  xxs: @spaceXxs;
  xs: @spaceXs;
  norm0_5: @spaceNorm0_5;
  sm: @spaceSm;
  norm: @spaceNorm;
  md: @spaceMd;
  norm1_5: @spaceNorm1_5;
  norm2: @spaceNorm2;
  lg: @spaceLg;
  norm2_5: @spaceNorm2_5;
  norm3: @spaceNorm3;
  norm3_5: @spaceNorm3_5;
  xl: @spaceXl;
};
each(@spaces, {
  .margin-hor-@{key} {
    margin-left: @value;
    margin-right: @value;
  }
  .margin-right-@{key} {
    margin-right: @value;
  }
  .margin-top-@{key} {
    margin-top: @value;
  }
  .margin-bottom-@{key} {
    margin-bottom: @value;
  }
  .margin-vert-@{key} {
    margin-top: @value;
    margin-bottom: @value;
  }
  .margin-hor-neg-@{key} {
    margin-left: -@value;
    margin-right: -@value;
  }
  .margin-vert-neg-@{key} {
    margin-top: @value;
    margin-bottom: @value;
  }
  .padding-hor-@{key} {
    padding-left: @value;
    padding-right: @value;
  }
  .padding-vert-@{key} {
    padding-top: @value;
    padding-bottom: @value;
  }
  .padding-bottom-@{key} {
    padding-bottom: @value;
  }
  .spaces-hor-@{key} {
    > * {
      .space-between(@value, horizontal);
    }
  }
  .spaces-vert-@{key} {
    > * {
      .space-between(@value, vertical);
    }
  }
  .gap-@{key} {
    gap: @value;
  }
  .margins-bottom-@{key} {
    > * {
      margin-bottom: @value;
    }
  }
});

.pipe-divided-columns {
  > .ant-col {
    position: relative;
    padding: @spaceMd @spaceLg;

    &:first-child {
      padding-left: @spaceNorm2;
    }
    &:last-child {
      padding-right: @spaceNorm2;
      &::after {
        display: none;
      }
    }
    &::after {
      content: '|';
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -10px;
      line-height: 1;
      font-size: 20px;
      font-weight: 100;
      color: @colorGrey9;
    }
  }
}

.no-margin-form-items {
  .ant-form-item {
    margin-bottom: 0;
    margin-right: 0;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
}

each(range(8,24), {
  .icon-@{value} {
    .ant-btn &, &, &.IconButton {
      font-size: @value * 1px;
    }
  }
});

.nl-as-newline {
  white-space: pre-line;
}

.TwoLinesSeparator {
  position: relative;
  height: 0;
  margin: 1px 0 -1px 0;
  &::before {
    content: ' ';
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    border-top: 1px solid @colorGrey8;
  }
  border-top: 1px solid @colorWhite;
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid @colorWhite;
  }

  &.hide-first:first-child,
  &.hide-last:last-child {
    display: none;
  }
}
.DashedTwoLinesSeparator {
  .TwoLinesSeparator();
  &,
  &::before,
  &::after {
    border-top-style: dashed;
  }
}

.text-uppercase {
  text-transform: uppercase;
}
.text-no-transform {
  text-transform: initial;
}

.text-center {
  text-align: center;
}

@font-sizes: {
  xs: @font-size-xs;
  sm-minus: @font-size-sm-minus;
  sm: @font-size-sm;
  sm-plus: @font-size-sm-plus;
  base: @font-size-base;
  lg: @font-size-lg;
};
each(@font-sizes, {
  .font-size-@{key} {
    font-size: @value;
  }
});

.width-50 {
  width: 50px;
}
.width-60 {
  width: 60px;
}
.width-70 {
  width: 70px;
}
.width-80 {
  width: 80px;
}
.width-90 {
  width: 90px;
}
.width-100 {
  width: 100px;
}
.width-110 {
  width: 110px;
}
.width-115 {
  width: 115px;
}
.width-120 {
  width: 120px;
}
.width-130 {
  width: 130px;
}
.width-140 {
  width: 140px;
}
.width-150 {
  width: 150px;
}
.width-160 {
  width: 160px;
}
.width-180 {
  width: 180px;
}
.width-190 {
  width: 190px;
}
.width-210 {
  width: 210px;
}
.width-100p {
  width: 100%;
}

.number-in-circle(@size: 25px, @font-size: @heading-4-size, @font-weight: bold, @background-color: @colorGreyBluish) {
  height: @size;
  width: @size;
  .flex-center();
  border-radius: 100%;
  background-color: @background-color;
  font-size: @font-size;
  font-weight: @font-weight;
  color: @colorWhite;
}
.number-in-circle {
  .number-in-circle();
}

.line-height-1 {
  line-height: 1;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-pointer-events {
  pointer-events: none;
}
