.ant-modal.ReportsDialog {
  .ant-modal-content {
    @media (min-width: @screen-lg-min) {
      width: 800px !important;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: 500px !important;
    }
    @media (max-width: @screen-sm-max) {
      width: 320px !important;
    }
  }
  .ant-modal-body {
    align-items: stretch;

    @media (min-width: @screen-lg-min) {
      padding: @spaceXl @spaceNorm6;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      padding: @spaceNorm1_5 @spaceNorm2;
    }
    @media (max-width: @screen-sm-max) {
      padding: @spaceNorm;
    }

    h2.ant-typography {
      font-size: @font-size-30;
      margin-bottom: 0;
    }
    h4.ant-typography {
      font-weight: 300;
      margin-bottom: @spaceNorm2;
      text-align: center;
    }
  }

  .SimpleDialog-Icon {
    > img {
      width: 56px;
      height: 59px;
    }
    &::before {
      display: none;
    }
  }

  form {
    margin-bottom: 0;
  }
}

.ReportsDialog__Section {
  padding: @spaceNorm2;
  background-color: @colorWhite2_5;
  border: 1px solid @colorWhite;
  border-radius: @borderRadiusSm;
  box-shadow: 1px 1px 20px @colorGreyBluish4_1;
}

.ReportsDialog__Toolbar {
  .ReportsDialog__Section();
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: @spaceNorm;
  padding-top: 0;
  padding-bottom: 0;
  border-width: 2px;
}

.ReportsDialog__ToolbarTitle {
  font-family: 'League Gothic';
  font-size: @font-size-40;
  color: @colorGrey6;
}

.ReportsDialog__Content {
  @media (min-width: @screen-lg-min) {
    min-height: 480px;
    max-height: 560px;
  }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    min-height: 300px;
    max-height: 360px;
  }
  @media (max-width: @screen-sm-max) {
    min-height: 240px;
    max-height: 300px;
  }

  display: flex;
  flex-direction: column;
  gap: @spaceNorm;
  margin: @spaceNorm1_5 0;
}

.ReportsDialog__Section__Title {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: @spaceNorm;
  border-top: 1px solid @colorWhite;
  border-bottom: 1px solid @colorGrey7_8;

  h3.ant-typography {
    font-family: 'League Gothic';
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
  }

  .ant-modal.ReportsDialog & .InlineLink {
    color: @colorRed;
    font-size: @font-size-sm;
    text-transform: uppercase;
    &.disabled {
      opacity: 0.35;
    }
  }

  .InputSearchFlat {
    height: 24px !important;
    border-radius: @borderRadiusLg !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 276px;
  }
}

.ReportsDialog__SelectionSection {
  border-radius: @borderRadiusXs;
  border: 1px solid @colorGrey7_8;
  display: flex;
  flex-direction: column;
  padding-bottom: @spaceNorm;
  flex: 1;

  .ant-row {
    width: 100%;
  }
}

.ReportsDialog__SelectionSection__Title {
  height: 40px;
  line-height: 1;
  padding: 0 @spaceNorm2;
  display: flex;
  align-items: center;
  font-family: 'League Gothic';
  font-size: @font-size-30;
  text-transform: uppercase;
  color: @colorWhite;
  background-color: @colorGreyBluish7_2;
  .border-radius(@borderRadiusXs, top);

  @{fa-selector} {
    color: @colorGreyBluish;
  }
}

.ReportsDialog__SelectionSection__Footer {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 @spaceNorm1_5;
  background-color: @colorGrey9_9;
  border: 1px solid @colorWhite;
  border-top-width: 0;
  .border-radius(@side: bottom);
  box-shadow:
    0px 2px 6px rgba(@colorGreyBluish, 0.35),
    inset 0 -1px 8px @colorGrey9_7;
  margin: 0 @spaceNorm0_5 0 @spaceNorm0_5;
}

.ReportsDialog__SelectionSection__Subtitle {
  height: 40px;
  padding: 0 @spaceNorm2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: @spaceNorm2;
  background-color: @colorGrey9_9;
  .border-radius(@borderRadiusXs, bottom);
  color: @colorGreyBluish;
  box-shadow:
    0 2px 6px rgba(@colorGreyBluish7_2, 0.35),
    inset 0 -1px 8px @colorGrey9_7;

  .label {
    font-weight: 500;
    margin-right: @spaceNorm0_5;
    line-height: 1;
    text-transform: uppercase;
  }
}
.ReportsDialog__SelectionSection__Subtitle__Left {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.ReportsDialog__SelectionTag.ant-tag {
  background-color: @colorRed;
  color: @colorWhite;
  border-radius: @borderRadiusLg;
  font-family: @fontStd;
  font-weight: 500;
  padding: O @spaceNorm;
}

.ReportsDialog__SelectionSection__Header {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 @spaceXl;
  font-family: @fontCondensed;
  font-weight: bold;
  color: @colorGreyBluish;
  border-bottom: 1px solid @colorWhite;
}

.ReportsDialog__SelectionSection__Body {
  padding: @spaceXs @spaceNorm2 0 @spaceNorm2;
  display: flex;
  flex-direction: column;
  gap: @spaceNorm;
}

.ReportsDialog__SelectionSection__GroupLabel {
  font-family: @fontCondensed;
  font-weight: bold;
  font-size: @font-size-sm;
  text-transform: uppercase;
  margin-bottom: @spaceXxs - @spaceNorm;

  &--Partner {
    color: @colorBlue1_5;
    font-style: italic;
  }
  &--NonPartner {
    color: @colorRed;
  }
}

.ReportsDialog__SelectionSection__Row {
  position: relative;
  height: 40px;
  border-radius: @borderRadiusXs;
  display: flex;
  align-items: center;
  padding: 0 @spaceNorm2;
  font-weight: 500;
  overflow: hidden;

  &--Even {
    background-color: @colorWhite;
    box-shadow: 1px 1px 12px rgba(@colorGreyBluish4_1, 0.85);
  }
  &--Odd {
    background-color: @colorWhite7;
    box-shadow: 1px 1px 12px rgba(@colorGreyBluish4_1, 0.85);
  }
}

.ReportsDialog__SelectionSection__Row--Active {
  .absolute(0);
  border-radius: @borderRadiusXs;
  border: 2px solid @colorRed;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 55px;
    width: 55px;
    transform: translate(50%, -50%) rotateZ(45deg);
    background-color: @colorRed;
  }

  &::after {
    .fa-like('\f00c');
    color: @colorWhite;
    position: absolute;
    top: @spaceXs;
    right: @spaceXs;
  }
}

.ReportsDialog__DateRange__CalendarPopup {
  position: relative;
  width: 100%;

  > * {
    width: 100%;
  }
}

.ReportsDialog__DateRange__InputLike {
  cursor: text;
  padding-left: @spaceNorm2;
  gap: @spaceSm;
  font-size: @heading-1_5-size;
  font-family: 'League Gothic';
  color: @colorGrey7_6;
  line-height: 1;

  &.has-value {
    color: @colorRed;
  }
}

.ReportsDialog__DateRange__CalendarContainer {
  position: absolute;
  top: 100% + @spaceNorm;
  left: 0;
  right: 0;

  .ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
    width: 264px;
  }
}

.ReportsDialog__DateRange__Envelope {
  background-color: @colorWhite2;
  border-radius: @borderRadiusSm;
  border: 1px solid @colorWhite;
  box-shadow: 0 2px 20px @colorGreyBluish3;
  padding: @spaceNorm;
}

.ReportsDialog__DateRange__Calendar.ant-picker-calendar {
  background-color: transparent;

  .ant-picker-panel {
    background-color: transparent;
  }

  .ant-picker-content {
    height: 220px;
  }

  .ant-picker-body {
    padding: 0;

    thead > tr {
      height: 26px;
      border-top: 1px solid @colorGrey7;
      border-bottom: 1px solid @colorGrey7;

      th {
        line-height: 26px;
        font-weight: bold;
        font-size: @font-size-sm;
        color: @colorGreyBluish7_2;
        text-transform: uppercase;
      }
    }
    tbody > tr {
      &:first-child {
        padding-top: @spaceXs;
      }

      td {
        font-weight: 500;
        font-size: @font-size-lg;
      }

      .ant-picker-cell {
        padding: 0;
        color: @colorGrey7_4;

        .ant-picker-cell-inner {
          width: 32px;
          height: 32px;
          line-height: 32px;
        }
      }
      .ant-picker-cell-in-view {
        color: @colorGreyBluish2;
      }

      .ant-picker-calendar-date-value {
        display: none;
      }
    }
  }
}
.ReportsDialog__DateRange__Calendar__MonthSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: @spaceNorm2;
  margin: @spaceXxs 0 @spaceXs 0;
  font-size: @font-size-lg;
  font-weight: 500;
  color: @colorGreyDark5;

  @{fa-selector} {
    color: @colorGrey6;
  }
}

.ReportsDialog__SelectionSection--Summary {
  flex: initial;
  padding: @spaceNorm2;
  padding-top: @spaceNorm3;
  margin-top: -@spaceNorm - 1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.ReportsDialog__Summary__DateBody {
  height: 50px;
  justify-content: center;
  font-size: @heading-1_5-size;
  font-family: 'League Gothic';
  color: @colorRed;
}

.ant-modal.SimpleDialog.ReportsDoneDialog {
  .ant-modal-content {
    @media (min-width: @screen-md-min) {
      width: 460px !important;
    }
  }
  .ant-modal-body {
    align-items: center;

    @media (min-width: @screen-md-min) {
      padding: @spaceNorm3_5 @spaceNorm;
    }
    @media (max-width: @screen-sm-max) {
      padding: @spaceNorm2 @spaceNorm;
    }

    h1.ant-typography {
      line-height: 1;
      font-size: @font-size-30;
      margin-top: @spaceSm;
      margin-bottom: @spaceLg;
      color: @colorGrey2;
    }
    h4.ant-typography {
      font-weight: normal;
      text-align: center;
      color: @colorGrey2;
    }
  }
}
