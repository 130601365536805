.Accordion {
  &.Accordion--fullHeight {
    height: 100%;

    &,
    .Accordion__Item {
      display: flex;
      flex-direction: column;
    }
    .Accordion__Item.Accordion__Item--active,
    .Accordion__Body.Accordion__Body--active {
      .Flex1();
    }
  }
  &.Accordion--isInFlexContainer.Accordion--fullHeight {
    .Flex1();

    .Accordion__Body {
      display: flex;
      flex-direction: column;

      > * {
        .Flex1();
      }
    }
  }
}

.Accordion__Item {
  margin-bottom: @spaceNorm;
}

.Accordion__Header {
  display: flex;
  align-items: center;
  background-color: @colorGreyBluish;
  border-radius: @borderRadiusXs;
  height: 30px;
  font-family: @fontCondensed;
  font-weight: bold;
  color: @colorWhite;
  padding: 0;
  cursor: pointer;
  margin-bottom: 0;
  transition: margin-bottom @animationDurationMd ease-in-out;

  @{fa-selector} {
    padding: 0 @spaceNorm0_5;
  }

  &--active {
    margin-bottom: @spaceNorm;
  }
}
