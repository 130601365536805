.ant-popover {
  padding-top: 6px;

  .ant-popover-title {
    color: @colorGrey4;
  }

  .ant-popover-content {
    .ant-popover-inner {
      border-radius: @borderRadiusSm;
      background-color: @colorWhite6;
      box-shadow: 0 2px 20px @colorGreyBluish3;
    }
  }
}

.ant-picker {
  width: 100%;
}

.ant-list-pagination {
  &:first-child {
    text-align: left;
    margin-top: 0;
    margin-bottom: 25px;
  }
}

h4.ant-typography,
.ant-typography h4 {
  font-weight: 500;
}

.ant-tag {
  font-family: @font3;
  font-weight: bold;
  display: inline-flex;
  align-items: center;

  .ant-tag-close-icon {
    display: flex;
  }

  &.ant-tag-has-color {
    border-width: 0;
  }

  &.ant-tag--DarkTheme {
    background-color: @colorGreyDark3;
    border: 1px solid @colorGreyDark5;
    font-family: @fontCondensed;
    font-weight: normal;
    color: @colorGreyBluish8;
    font-size: @font-size-sm;
    border-radius: @borderRadiusXs;

    .ant-tag-close-icon {
      &,
      &:hover {
        color: @colorGreyBluish7_4;
        margin-left: @spaceSm;
      }
    }
  }
}

.ant-badge-count {
  min-width: 24px;
  height: 15px;
  border-radius: 8.5px;
  padding: 0 8px;
  line-height: 16px;
  font-weight: bold;
}

.ant-popover {
  &.no-arrow {
    .ant-popover-arrow {
      display: none;
    }
  }

  .ant-popover-inner {
    border-radius: @borderRadiusSm;
    background-color: @colorWhite4;
    box-shadow: 0px 2px 20px @colorGreyBluish3;
  }

  .ant-popover-inner-content {
    padding: @spaceSm @spaceMd;
  }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  > a {
    margin: 0;
    padding: 0;
  }
}

.ant-tooltip {
  .def-placement(@placement, @direction, @position) {
    &.ant-tooltip-placement-@{placement} .ant-tooltip-inner {
      .with-triangle(@width: if((@direction =up) or (@direction =down), 12px, 7px),
        @height: if((@direction =up) or (@direction =down), 7px, 12px),
        @direction: @direction,
        @border-color: @colorWhite,
        @background-color: @colorOrange2,
        @position: @position,
        @outer-styles: {
          filter: drop-shadow(0 2px 6px rgba(@colorGreyBluish3, 0.65))
        });
    }

    &.ant-tooltip-custom-lines {
      max-width: none;
      .nl-as-newline();
    }
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .def-placement(@placement: ~'top',
    @direction: down,
    @position: {
      left: 50%; top: 100%; transform: translateX(-50%);
    });

  .def-placement(@placement: ~'bottom',
    @direction: up,
    @position: {
      left: 50%; bottom: 100%; transform: translateX(-50%);
    });

  .def-placement(@placement: ~'topRight',
    @direction: down,
    @position: {
      right: 10%; top: 100%;
    });

  .def-placement(@placement: ~'topLeft',
      @direction: down,
      @position: {
        left: 10%; top: 100%;
      });

  .ant-tooltip-inner {
    border-radius: @borderRadiusSm;
    font-family: @font5;
    font-weight: bold;
    line-height: 1;
    padding: @spaceSm @spaceNorm;
    box-shadow: none;
    min-height: 0;
  }

  &.ant-tooltip--no-arrow {
    padding: 0;

    .ant-tooltip-inner {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &.ant-tooltip--sm .ant-tooltip-inner {
    border-radius: @borderRadiusXs;
    font-family: @font4;
    line-height: 1.5;
    font-size: @font-size-sm-minus;
    font-weight: bold;
    padding: @spaceXs @spaceSm;
    box-shadow: none;
  }

  &.ant-tooltip--arrow-at-left {
    .ant-tooltip-inner {
      transform: translateX(50%);
      left: -24px;

      &::before,
      &::after {
        left: 24px;
      }
    }
  }
}

.text-center .ant-tooltip-inner {
  text-align: center;
}

.ant-menu-title-content {
  transition: none;
}

.ant-message {
  z-index: 2047;
}
