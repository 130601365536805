.AdminUserDetail__VerticalButtons {
  padding: @spaceNorm;
  display: flex;
  flex-direction: column;
  gap: @spaceMd;

  > .ant-btn {
    width: 40px;
  }
}

.AdminUserDetail__ActiveLabel {
  color: @colorGreyBluish7_4;
  font-size: @heading-4-size;
  font-weight: 500;
  letter-spacing: 0.27px;
  text-transform: uppercase;
}
