.CustomGroupAutocomplete {
  position: relative;

  &.CustomGroupAutocomplete__WithArrow::after {
    content: '';
    .fa-like('\f0d7');
    pointer-events: none;
    .selectArrow();
  }
}

.CustomGroupAutocomplete-Popup.ant-popover {
  padding: 0;
  padding-right: @spaceNorm;
  min-width: 280px;
  max-width: 100vw;

  .ant-popover-content {
    .ant-popover-inner {
      border-radius: @borderRadiusXs;
      box-shadow: 0 1px 20px @colorGreyBluish3;
      border: 1px solid @colorWhite;
      background-color: @colorWhite2_5;
      overflow: hidden;

      .ant-popover-inner-content {
        padding: 0;

        .Scrollbar {
          max-height: 240px;
        }
      }
    }
  }

  .CustomGroupAutocomplete-Group {
    .space-between(@spaceNorm0_5, vertical);
  }

  .CustomGroupAutocomplete-GroupHeader {
    font-size: @font-size-lg;
    font-weight: bold;
    color: @colorGreyBluish;
    padding: 0 @spaceNorm;

    @{fa-selector} {
      width: 10px;
    }
  }

  .Clickable {
    padding: 0 @spaceNorm;
    line-height: 30px;
    font-size: @font-size-lg-minus;
    font-weight: 500;
    color: @colorBlack;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &.active {
      background-color: rgba(@colorBlue2, 0.26);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
