.gm-style {
  @bg: @colorOrange2;
  @color: @colorWhite;
  @border: @colorWhite;
  @boxShadow: 0 2px 6px rgba(@colorGreyBluish3, 0.65);

  .gm-style-iw-t {
    border-width: 0;

    .with-triangle(
      @width: 12px,
      @height: 7px,
      @direction: down,
      @border-color: @border,
      @background-color: @bg,
      @position: {left: 0; top: 100%; transform: translate(-50%, -1px);},
      @outer-styles: {filter: drop-shadow(@boxShadow)},
      @apply-own-styles: false
    );
    &::after {
      background: initial;
      box-shadow: none;
    }
  }

  .gm-style-iw-tc {
    display: none;
  }

  .gm-style-iw-c {
    border-radius: @borderRadiusSm;
    font-family: @font5;
    font-weight: bold;
    line-height: 22px;
    padding: @spaceNorm0_5 @spaceNorm !important;
    border: 1px solid @border;
    box-shadow: @boxShadow;
    background-color: @bg;
    color: @color;
    font-size: @font-size-base;

    > button {
      display: none !important;
      > img {
        display: none !important;
      }
    }
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }
}
