.MapCard {
  width: 100%;
  height: 100%;
  background-color: @colorWhite;
  padding: @spaceSm;
  border-radius: @borderRadiusMd;
  box-shadow: 1px 1px 6px @colorGrey8;

  &.expanded {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      height: 320px;
    }
    @media (max-width: @screen-sm-max) {
      height: 220px;
    }
  }
}

.CollapsibleMapCard {
  display: flex;
  flex-direction: column;

  height: 21px;
  transition: height @animationDurationMd ease-in-out;

  .CollapseControl {
    margin-top: -@spaceSm;
    color: @colorRed;
    font-size: @font-size-base;
    font-weight: 500;
  }

  .CollapseContent {
    flex: 1;
    opacity: 1;
    transition: opacity @animationDurationMd ease-in-out;
    &.hidden {
      opacity: 0;
    }
  }
}

.MapContainer {
  width: 100%;
  height: 100%;
}

.MapDropdownMenu-Overlay {
  .dropdown-with-triangle(
    @dropdownPrefix: ~'ant-popover',
    @menuSelector: ~'.ant-popover-content',
    @menuItemPrefix: ~'ant-menu',
    @menuCustom: {
      width: auto;
    }
  );

  &.ant-popover {
    padding-top: 0;

    .ant-popover-inner {
      border-radius: inherit;
      overflow: hidden;
      box-shadow: none;
      background-color: transparent;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-menu {
    border-width: 0;
    background-color: transparent;
  }
  .ant-menu .ant-menu-item {
    padding: 0;
    font-size: @font-size-base;
    line-height: 25px;
    font-weight: 500;
  }
  .Scrollbar-TrackVertical {
    z-index: 1;
    right: 0;
  }
}

.Cluster__FakeTooltipTrigger {
  position: absolute;
  cursor: pointer;
}
