.IconButton {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  display: inline-flex;
  .ant-col > & {
    display: flex;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-Icon {
    line-height: 1;
    font-size: 1em;
  }
  .size(@iconSize, @labelSize) {
    .IconButton-Icon {
      font-size: @iconSize;
    }
    .IconButton-Label {
      font-size: @labelSize;
    }
  }
  &.size-md {
    .size(1.25em, 0.5em);
  }
  &-HasLabel,
  &.size-lg {
    .size(1.5em, 0.57em);
  }
  &-Label {
    font-family: @font4;
    font-weight: bold;
    line-height: 1;
  }

  @{fa-selector} {
    display: block;
  }
}

.InlineLink {
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  display: inline-flex;
  .ant-col > & {
    display: flex;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.loading {
    cursor: not-allowed;
  }

  > @{fa-selector} {
    &:last-child {
      margin-left: @spaceXs;
    }
    &:first-child {
      margin-right: @spaceXs;
    }
  }

  li > & {
    width: 100%;
  }

  &.size-sm {
    font-size: @font-size-sm-plus;
  }
}
.InlineLink--Grey {
  color: @colorGrey3 !important;
}
.InlineLink--Red {
  color: @colorRed2 !important;
}

.DotButton {
  cursor: pointer;
  font-size: 12px;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background-color: @colorGrey7_2;
  &.active {
    background-color: @colorGrey2;
  }
}
