// Summary of totals
.NewShipmentPackagesSummary {
  padding: @spaceNorm;

  &-Badge {
    .flex-center();
    height: @input-height-md;
    background-color: @colorWhite2;
    box-shadow:
      0 1px 0 rgba(@colorGreyBluish, 0.25),
      1px 1px 6px @colorGrey8_1;
    border-radius: @borderRadiusSm;
    color: @colorRed;
    font-size: @heading-4-size;
    font-weight: 500;
    white-space: nowrap;

    .responsive-xs-vs-sm(
      @xs: {
        width: 77px;
      },
      @sm-and-bigger: {
        width: 90px;
      }
    );
  }

  &-Label {
    color: @colorGreyBluish2;
    font-size: @font-size-lg;
    font-weight: bold;
    font-family: @fontCondensed;
    margin-bottom: @spaceNorm0_5;

    .responsive-xs-vs-sm(
      @xs: {
        font-size: @font-size-lg-minus;
      },
      @sm-and-bigger: {
        font-size: @font-size-lg;
      }
    );
  }
}

// Number buttons to change package in small breakpoints
.NewShipmentPackagesListSlider {
  .NewShipmentPackagesSummary + & {
    margin-top: -@spaceNorm;
  }

  margin-bottom: 0;
  .SlidingContent-ContentTab > *:last-child {
    margin-bottom: @spaceNorm2;
  }

  &-Header {
    margin-bottom: @spaceNorm;

    .IconButton {
      display: flex;
      font-size: 19px;
      color: @colorRed;
    }
  }
  &-Label {
    font-size: @font-size-lg;
    font-family: @fontCondensed;
    font-weight: bold;
    color: @colorGrey3;
  }
  &-Button {
    .flex-center();
    width: 20px;
    height: 20px;
    font-size: @font-size-base;
    font-family: @fontCondensed;
    font-weight: bold;
    color: @colorGrey3;
    border-radius: @borderRadiusSm;
    cursor: pointer;

    &.active {
      background-color: @colorWhite;
      color: @colorRed;
      border: 1px solid @colorRed;
      box-shadow:
        4px 4px 5px rgba(@colorBlack, 0.14),
        1px 1px 10px rgba(@colorBlack, 0.12);
    }
  }
}

// The stripe containing index badge
.NewShipmentPackage-Header {
  height: 40px;
  background-color: @colorGrey9_6;
  padding: 0 @spaceNorm;

  .InputText {
    height: 28px;
  }

  // In edit mode
  .NewShipmentPackage.editing & {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        height: 40px;
      },
      @lg-and-bigger: {
        height: 50px;
      }
    );
  }
}

// Title of section
.NewShipmentPackage-Title {
  font-family: @fontCondensed;
  font-weight: bold;
  line-height: 17px;
  height: 17px;
  margin-bottom: @spaceSm;
  white-space: nowrap;

  .responsive-md-vs-lg(
    @md-and-smaller: {
      font-size: @font-size-base;
    },
    @lg-and-bigger: {
      font-size: @font-size-lg;
    }
  );

  // In edit mode
  .NewShipmentPackage.editing & {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        height: 20px;
        line-height: 20px;
        padding-left: @spaceNorm;
        font-size: @font-size-base;
        margin-top: @spaceSm;
        margin-bottom: @spaceSm;
        border-width: 0;
        box-shadow: none;
      },
      @lg-and-bigger: {
        height: 30px;
        line-height: 30px;
        padding-left: @spaceNorm2;
        font-size: @font-size-lg;
        border-top: 1px solid @colorGrey7_8;
        border-bottom: 1px solid @colorWhite;
        box-shadow: 0 1px 0 @colorWhite;
        margin-bottom: @spaceNorm;
      }
    );
  }
}

.NewShipmentPackage-Section {
  overflow: hidden;

  &.merge-with-prev {
    overflow: visible;
    .responsive-md-vs-lg(
      @md-and-smaller: {
        margin-top: @spaceNorm - @spaceNorm1_5;
      },
      @lg-and-bigger: {
        margin-top: @spaceNorm - @spaceNorm1_5;
      }
    );
    &:not(.has-two-lines) > .NewShipmentPackage-Title {
      margin-top: 0;
    }
  }
  &.title-space-xxs > .NewShipmentPackage-Title {
    margin-bottom: @spaceXxs;
  }
}
.NewShipmentPackage-SectionBody {
  .responsive-lg-vs-xl(
    @lg-and-smaller: {
      padding: 0 @spaceNorm @spaceNorm1_5 @spaceNorm;
    },
    @xl-and-bigger: {
      padding: 0 @spaceNorm2 @spaceNorm1_5 @spaceNorm2;
    }
  );
  // Level 2 lines - they should cover the full width too
  .TwoLines {
    .responsive-lg-vs-xl(
      @lg-and-smaller: {
        margin-left: -@spaceNorm;
        margin-right: -@spaceNorm;
      },
      @xl-and-bigger: {
        margin-left: -@spaceNorm2;
        margin-right: -@spaceNorm2;
      }
    );
  }
  .NewShipmentPackage-Section & > * {
    .space-between(@spaceNorm, vertical);
  }
}
.NewShipmentPackage-Subtitle {
  font-family: @fontCondensed;
  font-size: @font-size-lg;
  font-weight: bold;
  color: @colorGreyBluish2;
  margin-bottom: @spaceNorm;
}
.NewShipmentPackage-SubsectionParent {
  .responsive-md-vs-lg(
    @md-and-smaller: {
      margin-left: -@spaceNorm;
      margin-right: -@spaceNorm;
    },
    @lg-and-bigger: {
      margin-left: 0;
      margin-right: 0;
    }
  );
}
.NewShipmentPackage-Subsection {
  padding: @spaceXs @spaceNorm 0 @spaceNorm;
  .responsive-md-vs-lg(
    @md-and-smaller: {
      padding-bottom: 0;
      border-width: 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    },
    @lg-and-bigger: {
      border: 1px solid @colorWhite;
      background-color: @colorWhite2;
      border-radius: @borderRadiusSm;
      padding-bottom: @spaceNorm1_5;
      box-shadow: -1px -1px 2px rgba(@colorGreyBluish, 0.35),
        -1px -2px 0 @colorWhite, 6px 6px 19px rgba(@colorGreyBluish, 0.35);
    }
  );
}
.NewShipmentPackage-SubsectionTitle {
  font-size: @font-size-sm-plus;
  color: @colorGreyBluish2;
  font-family: @fontCondensed;
  font-weight: bold;
  margin-bottom: @spaceNorm0_5;
  text-transform: uppercase;
}

// Package editing mode elements
.NewShipmentPackage-PrevTabsInfo {
  padding: @spaceNorm0_5 @spaceNorm;

  .NewShipmentPackage-Header & {
    padding: 0;
  }
}

// Package preview mode elements
.NewShipmentPackage-PreviewBody {
  .responsive-lg-vs-xl(
    @lg-and-smaller: {
      padding: 0 @spaceNorm;
    },
    @xl-and-bigger: {
      padding: 0 @spaceNorm2;
    }
  );
}
.NewShipmentPackage-PreviewRow {
  padding: @spaceNorm 0;

  > .ant-row {
    margin-bottom: -@spaceNorm;
    > .ant-col {
      margin-bottom: @spaceNorm;
    }
  }
}
.NewShipmentPackage-PreviewRowCols {
  > .ant-col {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        &.size-sm-1 {
          width: 29%;
        }
        &.size-sm-2 {
          width: 33%;
        }
        &.size-sm-3 {
          width: 38%;
        }
        &.size-sm-4 {
          width: 62%;
        }
      },
      @lg-and-bigger: {
        &.size-lg-1 {
          width: 20%;
        }
        &.size-lg-2 {
          width: 22%;
        }
        &.size-lg-3 {
          width: 26%;
        }
        &.size-lg-4 {
          width: 32%;
        }
      }
    );
  }
}
.NewShipmentPackage-BilledWeight {
  font-size: @font-size-sm-plus;
  font-weight: bold;
  font-family: @fontCondensed;
}
.NewShipmentPackage-DeviceAddons {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: device-addons;

  li {
    display: flex;
    margin-top: @spaceXs;
    margin-bottom: @spaceNorm0_5;
    counter-increment: device-addons;

    &::before {
      width: 22px;
      content: counter(device-addons) '). ';
    }
  }
}

// Body of package card - both editing and preview
.NewShipmentPackage {
  background-color: @colorWhite6;

  .DashedTwoLinesSeparator {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        margin-left: @spaceNorm;
        margin-right: @spaceNorm;
      },
      @lg-and-bigger: {
        margin-left: 0;
        margin-right: 0;
      }
    );
  }

  .SymbolCol {
    .flex-center();
    min-height: @input-height-md;
  }

  .DimensionSeparator {
    font-size: 22px;
    font-weight: bold;
    color: @colorGreyBluish;
    line-height: 1;

    .responsive-md-vs-lg-vs-xl(
      @md-and-smaller: {
        margin: 0;
      },
      @lg: {
        margin: 0 -@spaceXxs;
      },
      @xl-and-bigger: {
        margin: 0;
      }
    );
  }

  .DimensionEquals {
    .flex-center();
    width: @input-height-md;
    min-width: @input-height-md;
    height: @input-height-md;
    border-radius: @borderRadiusSm;
    background-color: @colorGreyBluish3;

    .responsive-md-vs-lg-vs-xl(
      @md-and-smaller: {
        margin: 0;
      },
      @lg: {
        margin: 0;
      },
      @xl-and-bigger: {
        margin: 0 @spaceMd;
      }
    );

    @{fa-selector} {
      font-size: 18px;
      display: block;
      color: @colorWhite;
    }
  }

  .DimensionalWeightResultLabel {
    text-align: right;
    font-size: @font-size-sm;
    color: @colorGreyBluish;
    font-family: @fontCondensed;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: @spaceXxs;
  }

  .DimensionalWeightResult {
    text-align: right;
    font-size: @font-size-20;
    font-weight: 500;
    color: @colorGrey1_6;
    line-height: 1;

    &.no-value {
      font-weight: 400;
      color: @colorGreyBluish;
    }
  }

  .MonitorDeviceIcon {
    font-size: 18px;
    color: @colorRed;
  }

  .ant-radio-wrapper {
    .space-between(@spaceNorm1_5, horizontal);
  }

  .PieceList-Button {
    @text: {
      color: @colorRed;
      font-size: @font-size-base;
      font-weight: 900;
    };
    @text();

    &.ant-btn.ant-btn-default.ant-btn-lg {
      @text();
      width: 170px;
      border-radius: @borderRadiusXs;
      border-color: @colorGreyBluish3;
    }

    @{fa-selector} {
      margin-left: @spaceSm;
    }
  }

  .NewShipmentPackage-TBDCheckbox {
    display: flex;
    align-items: center;
    height: 32px;
    border-bottom: 1px solid @colorGrey7_8;

    &.inline {
      .no-margin-form-items();
      height: auto;
      border-bottom-width: 0;
      padding: 0;
      margin: 0;
    }

    .ant-checkbox + span {
      font-size: @font-size-sm-plus;
    }

    .responsive-lg-vs-xl(
      @lg-and-smaller: {
        margin: -@spaceSm -@spaceNorm @spaceSm -@spaceNorm;
        padding: 0 @spaceNorm;

        &.active {
          margin-bottom: -@spaceNorm1_5;
        }
      },
      @xl-and-bigger: {
        margin: -@spaceNorm -@spaceNorm2 @spaceNorm -@spaceNorm2;
        padding: 0 @spaceNorm2;

        &.active {
          margin-bottom: -@spaceNorm1_5;
        }
      }
    );
  }

  .NewShipmentPackage-PackageDescriptionRow {
    &,
    &:last-child {
      margin-bottom: -@spaceNorm;
    }
    > .ant-col {
      margin-bottom: @spaceNorm;
    }
  }

  .NewShipmentPackage-WeightCalculationRow {
    flex-wrap: nowrap;

    &,
    .ant-checkbox-wrapper .ant-checkbox + span,
    .label {
      line-height: 18px;

      .responsive-xs-vs-sm(
        @xs: {
          font-size: @font-size-sm;
        },
        @sm-and-bigger: {
          font-size: @font-size-sm-plus;
        }
      );
    }

    .responsive-xs-vs-sm-vs-xl(
      @xs: {
        > .ant-col:first-child {
          white-space: pre-line;
        }
        .ant-checkbox-wrapper .ant-checkbox + span {
          padding-left: @spaceXs;
        }
      },
      @sm-to-lg: {
        > .ant-col:first-child {
          white-space: pre-line;
        }
        .ant-checkbox-wrapper .ant-checkbox + span {
          padding-left: @spaceSm;
        }
      },
      @xl-and-bigger: {
        > .ant-col:first-child {
          white-space: initial;
        }
        .ant-checkbox-wrapper .ant-checkbox + span {
          padding-left: @spaceXs;
        }
      }
    );
  }

  .NewShipmentPackage-VehicleType {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .label {
      margin-right: @spaceSm;
    }

    .responsive-xs-vs-sm-vs-xl(
      @xs: {
        > .ant-form-item {
          width: 175px;
        }
      },
      @sm-to-lg: {
        > .label {
          width: 100px;
        }
        > .ant-form-item {
          width: 210px;
        }
      },
      @xl-and-bigger: {
        > .label {
          width: auto;
        }
        > .ant-form-item {
          width: auto;
          .Flex1();
        }
      }
    );
  }

  .NewShipmentPackage-DeviceAddon {
    &::after {
      content: '';
      display: block;
      height: @spaceNorm;
      border-bottom: 1px solid @colorGrey8_1;
      .responsive-lg-vs-xl(
        @lg-and-smaller: {
          margin-left: -@spaceNorm;
          margin-right: -@spaceNorm;
        },
        @xl-and-bigger: {
          margin-left: -@spaceNorm2;
          margin-right: -@spaceNorm;
        }
      );
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

// Error message under the card
.NewShipmentPackage-ErrorMessage {
  color: @colorRed;
  .responsive-md-vs-lg(
    @md-and-smaller: {
      .NewShipmentPackagesListSlider .SlidingContent-ContentTab > &.has-content {
        margin-top: 0;
        margin-bottom: @spaceNorm;
      }
    },
    @lg-and-bigger: {
      &.has-content {
        margin-top: -@spaceNorm2;
      }
    }
  );
}
