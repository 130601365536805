.DatePicker-NoInlineEditing {
  .ant-picker-input {
    flex-direction: row-reverse;
    cursor: pointer;

    input {
      pointer-events: none;
    }
  }
}

.DatePreviewIcon {
  color: @colorGreyBluish7;
  margin-left: 0;

  font-size: 18px;
  margin-right: @spaceSm;
  @media (max-width: @screen-sm-max) {
    font-size: 16px;
    margin-right: @spaceXs;
  }
}

.DateRange-Preview {
  > .ant-col {
    :nth-child(2),
    :nth-child(3) {
      margin-right: @spaceXs;
      @media (max-width: @screen-sm-max) {
        margin-right: @spaceXxs;
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    font-size: @font-size-sm-plus;
  }

  // Popup freezes in sm and xs screens if button has any other height
  .ant-btn {
    height: 32px;
  }
}

.DateRange-Container {
  @{fa-selector} {
    font-size: 21px;
  }

  .DateRange-Dash {
    font-weight: 500;
    margin: 0 @spaceXxs;

    font-size: @font-size-sm-plus;
    @media (max-width: @screen-sm-max) {
      font-size: @font-size-sm;
    }
  }

  &,
  & .ant-form-item-label label,
  & .ant-form-item {
    color: @colorGreyBluish;
  }

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-label label {
      text-transform: uppercase;
      height: 16px;
      line-height: 16px;
      margin-bottom: @spaceXs;
      font-size: @font-size-xs;
    }
    .ant-picker {
      height: 24px;
      .ant-picker-input {
        input {
          font-size: @font-size-sm-plus;
          padding: 0 @spaceXs;
          text-align: center;
        }
      }
    }
  }
}
.DateRange-PresetButton   {
  display: block;
  width: 100%;
  margin: @spaceMd 0;
}

.DateTimePicker {
  display: flex;

  .Col-DatePicker {
    @media (min-width: @screen-md-min) {
      width: 160px;
      margin-right: @spaceNorm2;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: 150px;
      margin-right: @spaceNorm;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      width: 125px;
      margin-right: @spaceNorm;
    }
    @media (max-width: @screen-xs-max) {
      width: 105px;
      margin-right: @spaceNorm;
    }
  }
  .Col-TimePicker {
    @media (min-width: @screen-md-min) {
      width: 120px;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      width: 90px;
    }
    @media (max-width: @screen-xs-max) {
      width: 80px;
    }
  }
  // Keep in sync with the elements above
  .DateTimePicker-ValidationGroup {
    @media (min-width: @screen-md-min) {
      width: 200px + @spaceMd;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      width: 186px + @spaceNorm;
    }
    @media (max-width: @screen-xs-max) {
      width: 182px + @spaceNorm;
    }
  }

  &.expand-size {
    .Col-DatePicker {
      width: initial;
      flex: 3;
      min-width: 0;

      @media (min-width: @screen-md-min) {
        margin-right: @spaceNorm2;
      }
      @media (max-width: @screen-sm-max) {
        margin-right: @spaceNorm;
      }
    }
    .Col-TimePicker {
      width: initial;
      flex: 2;
      min-width: 0;
    }
  }
}

.DatePicker-FooterButton {
  color: @colorRed;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid @colorWhite4;

  &:last-child {
    border-bottom-width: 0;
  }
}
