@import './monitor/MonitorSearchFilters.less';

#MonitorPage  {
  .BaseLoggedPage-InnerContent {
    justify-content: flex-start;
  }
  .MonitorPage-IconButtons {
    // To make it seem visually aligned
    padding-right: 1px;
    .IconButton {
      color: @colorGrey3;
      &.active {
        color: @colorRed;
      }
    }
  }

  .MonitorPage-Jobs {
    margin-top: @spaceNorm2;
  }
}

.MonitorJobsAccordion {
  .Accordion__Item.Accordion__Item--active {
    .AnimatedCollapsible {
      overflow: visible;
    }
  }
}

.MonitorJobList {
  padding: @spaceNorm0_5 0 @spaceNorm 0;

  .MonitorJobList__Item {
    position: relative;
    .list-card();
    display: block;

    &.MonitorJobList__Item--HasTopBar {
      &.MonitorJobList__Item--RowLayout .MonitorJobList__ItemContent {
        margin-top: 12px - 6px;
      }
      &.MonitorJobList__Item--CardLayout .MonitorJobList__ItemContent {
        margin-top: 12px - 2px;
      }
    }
    .MonitorJobList__TopBar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 12px;
      line-height: 13px;
      background-color: rgba(@colorBlue2, 0.65);
      color: @colorWhite;
      font-size: @font-size-xs;
      font-weight: 900;
      font-style: italic;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      .border-radius(@borderRadiusXs, top);
    }

    .MonitorJobList__ItemContent {
      display: flex;
    }

     &.MonitorJobList__Item--RowLayout {
      min-height: 72px;
      padding: @spaceNorm;

      .MonitorJobList__ItemContent {
        flex-wrap: nowrap;
      }

      .MonitorJobList__Column {
        max-width: none;
        min-width: 0;
        .space-between(@spaceNorm);
        flex: 1;

        &:first-child {
          flex: initial;
          width: 120px;
        }
        &.wide-column {
          flex: 1.3;
        }
        &:last-child {
          flex: initial;
          width: auto;
        }
      }

      .MonitorJobList__Label {
        font-size: @font-size-xs;
        margin-bottom: 3px;
      }
    }
    &.MonitorJobList__Item--CardLayout {
      padding: @spaceSm @spaceNorm;

      @media (min-width: @screen-lg-min) {
        min-height: 150px;
      }
      @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        min-height: 70px;
      }
      @media (max-width: @screen-sm-max) {
        min-height: 75px;
      }

      .MonitorJobList__ItemContent {
        @media (min-width: @screen-lg-min) {
          flex-wrap: wrap;
          .MonitorJobList__Column {
            &:nth-child(odd) {
              width: 55%;
              max-width: 55%;
              flex: auto;
            }
            &:nth-child(even) {
              flex: 1;
              overflow: hidden;
            }
            &:nth-child(even) {
              margin-right: 0;
            }
            &:last-child,
            &:nth-last-child(2) {
              margin-bottom: 0;
            }
          }
        }
        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
          > .ant-row {
            width: 100%;
            > .ant-col:nth-child(1) {
              flex: 1.5;
              min-width: 0;
            }
            > .ant-col:nth-child(2) {
              flex: 1;
              min-width: 0;
            }
            > .ant-col:nth-child(3) {
              flex: 1;
              min-width: 0;
            }
          }
        }
        @media (max-width: @screen-sm-max) {
          > .ant-row {
            width: 100%;
            > .ant-col:nth-child(1) {
              flex: 6.5;
              min-width: 0;
            }
            > .ant-col:nth-child(2) {
              flex: 4.5;
              min-width: 0;
            }
          }
        }
      }

      .MonitorJobList__Column {
        margin-bottom: 6px;
        margin-right: @spaceMd;
      }

      .MonitorJobList__Label {
        font-size: @font-size-xs - 1px;
        font-weight: 500;
        line-height: 1;
      }
    }
  }

  .MonitorJobList__Column {
    display: flex;
    flex-direction: column;
  }

  .MonitorJobList__Label {
    text-transform: uppercase;
    color: @colorGreyBluish;
  }

  .MonitorJobList__Content {
    flex: 1;
    color: @colorGrey3;
    font-size: @font-size-sm-plus;
    font-weight: 500;
    line-height: 16px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    .ActivityIndicators {
      display: flex;
      align-items: flex-start;
      color: @colorGrey7_6;
      height: 100%;
      padding-top: @spaceXs;

      &-indicator {
        .space-between(@spaceNorm, horizontal);

        &.highlight {
          color: @colorBlue2;
        }
        &.orange {
          color: @colorOrange4;
        }
      }
      &-icon {
        font-size: 16px;
      }
    }
  }

  .MonitorJobList__Column.highlight &__Content {
    color: @colorRed;
  }
}

.MonitorNoData {
  margin: @spaceNorm2 auto 0 auto;
}

.MonitorLocationModeSelect {
  @media (min-width: @screen-md-min) {
    margin-bottom: @spaceNorm;
  }
  @media (max-width: @screen-sm-max) {
    margin-bottom: @spaceNorm0_5;
  }

  &-Inner {
    display: flex;
    align-items: center;
    height: 22px;
    margin-top: @spaceXxs;
    padding-left: @spaceNorm;
  }

  &-Label {
    text-transform: uppercase;
    font-weight: 500;
    margin-right: @spaceNorm;

    .responsive-xs-vs-sm(
      @xs: {
        font-size: @font-size-sm-minus;
      },
      @sm-and-bigger: {
        font-size: @font-size-sm;
      }
    );
  }
  .ant-radio-wrapper {
    flex-direction: row;

    .space-between(@spaceSm, horizontal);

    .ant-radio + span {
      font-weight: bold;
      font-family: @fontCondensed;
      padding-left: @spaceXs;
      padding-right: 0;

      .responsive-xs-vs-sm(
        @xs: {
          font-size: @font-size-sm;
        },
        @sm-and-bigger: {
          font-size: @font-size-sm-plus;
        }
      );
    }
  }
}

.MonitorMapLegend {
  position: absolute;
  top: @spaceNorm;
  right: @spaceNorm;

  background-color: @colorWhite2;
  border: 1px solid @colorWhite;
  border-radius: @borderRadiusMd;
  box-shadow:
    -1px -1px 1px @colorWhite,
    6px 6px 15px rgba(@colorGrey6, 0.35);
  padding-top: @spaceXxs;
  transition:
    width @animationDurationLg ease-in-out,
    height @animationDurationLg ease-in-out;
  overflow: hidden;

  &-Title {
    font-size: @font-size-lg;
    line-height: 24px;
    text-transform: uppercase;
    font-family: @fontCondensed;
    color: @colorGreyBluish7_2;
  }

  &-Text {
    width: 140px;
    font-size: @font-size-base;
    font-weight: 500;
    line-height: 24px;

    > div {
      display: flex;
      align-items: center;
      height: 24px;
    }

    .label {
      width: 93px;
    }
    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;

      @{fa-selector} {
        .absolute-middle-center();
        color: @colorWhite;
        font-size: @font-size-sm-minus;
      }

      .text-label {
        .absolute-middle-center();
        color: @colorWhite;
        font-size: @font-size-base;
        font-weight: bold;
      }
    }
  }

  &.closed {
    width: 75px;
    height: 30px;
    padding-left: @spaceSm;
  }
  &.open {
    width: 150px;
    height: 143px;
    padding-left: @spaceNorm;
  }
}

.MonitorJobList__DeleteButton {
  position: absolute;
  top: -@spaceXs;
  right: -@spaceXs;
  color: @colorRed;
  padding: 0 0 @spaceSm @spaceSm;
}

@keyframes monitor-item-delete {
  0% {
    transform: scale(1);
    min-height: 0;
    max-height: 160px;
    padding: 0;
    margin-bottom: @spaceNorm;
  }
  57% {
    transform: scale(0);
    min-height: 0;
    max-height: 160px;
    padding: 0;
    margin-bottom: @spaceNorm;
  }
  100% {
    transform: scale(0);
    min-height: 0;
    max-height: 0;
    padding: 0;
    margin-bottom: 0;
  }
}
.define-keyframes-animation(~'monitor-item-delete', monitor-item-delete, @duration: 1.4s /* 800ms + 600ms */);

@keyframes monitor-item-change-stage {
  0% {
    transform: scale(1);
    min-height: 0;
    max-height: 160px;
    padding: 0;
    margin-bottom: @spaceNorm;
    opacity: 0;
  }
  73% {
    transform: scale(0);
    min-height: 0;
    max-height: 160px;
    padding: 0;
    margin-bottom: @spaceNorm;
    opacity: 0;
  }
  74% {
    transform: scale(0);
    min-height: 0;
    max-height: 160px;
    padding: 0;
    margin-bottom: @spaceNorm;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    min-height: 0;
    max-height: 160px;
    padding: 0;
    margin-bottom: @spaceNorm;
    opacity: 1;
  }
}
.define-keyframes-animation(~'monitor-item-change-stage', monitor-item-change-stage, @duration: 2.3s /* 1400ms + 300ms + 800ms */);
