.AddressBookList {
  .Scrollbar-Thumb {
    z-index: 2;
  }

  @letter-shortcuts-width: 25px;
  @letter-shortcuts-closed-width: 15px;

  &-LetterShortcuts {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 4;
    .border-radius(@borderRadiusSm, left);
    background-color: @colorWhite6;
    width: @letter-shortcuts-width;
    padding: @spaceNorm 0;
    .flex-center();
    flex-direction: column;

    right: @letter-shortcuts-closed-width - @letter-shortcuts-width;
    transition: right @animationDurationMd ease-in-out;
    &.open {
      right: 0;
      box-shadow: 0 3px 10px rgba(@colorGreyBluish3, 0.5);
    }
    &.closed {
      right: -@letter-shortcuts-width;
    }

    .IconButton {
      font-size: 18px;
      color: @colorRed;
      border-bottom: 1px solid @colorRed;
      padding: 1px;
      margin-bottom: @spaceXs;
    }

    .Clickable {
      font-weight: bold;
      color: @colorRed;
      text-transform: uppercase;

      @media (min-width: @screen-lg-min) {
        font-size: @font-size-lg;
        line-height: 24px;
      }
      @media (max-width: @screen-md-max) {
        font-size: @font-size-base;
        line-height: 19px;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  &-Container {
    display: flex;
    margin-top: -@spaceNorm;

    .responsive-md-vs-lg(
      @md-and-smaller: {
        padding-right: 0;
      },
      @lg-and-bigger: {
        padding-right: 14px;
      }
    );

    > .Scrollbar > .Scrollbar-View > * > * {
      width: auto !important;
      right: -14px;
    }

    .AddressBookList-LetterShortcuts
      + &
      > .Scrollbar
      > .Scrollbar-TrackVertical {
      .responsive-md-vs-lg(
        @md-and-smaller: {
          margin-right: @letter-shortcuts-closed-width + @spaceXxs - 5px;
        },
        @lg-and-bigger: {
          margin-right: 0;
        }
      );
    }
    .AddressBookList-LetterShortcuts.open
      + &
      > .Scrollbar
      > .Scrollbar-TrackVertical {
      .responsive-md-vs-lg(
        @md-and-smaller: {
          margin-right: @letter-shortcuts-width + @spaceXxs - 5px;
        },
        @lg-and-bigger: {
          margin-right: 0;
        }
      );
    }
  }

  &-FixedSection {
    position: absolute;
    left: 0;
    pointer-events: none;

    .AddressBookList-SectionHeader {
      pointer-events: initial;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: @spaceNorm3;
      height: @spaceNorm;
      background-color: @colorWhite3;
    }
    &::before {
      top: 0;
    }
    &::after {
      top: @spaceNorm + 1px;
    }

    .responsive-md-vs-lg(
      @md-and-smaller: {
        top: 0;
        right: 0;
      },
      @lg-and-bigger: {
        top: @spaceNorm;
        right: @spaceNorm;
      }
    );
  }

  &-Section {
    border-top-width: 1px;
    border-top-style: solid;
    margin-top: @spaceNorm;

    .responsive-md-vs-lg(
      @md-and-smaller: {
        border-top-color: @colorWhite;
        margin-right: 0;
        padding: @spaceNorm 0 @spaceNorm2 0;
      },
      @lg-and-bigger: {
        border-top-color: @colorGrey9_4;
        margin-right: 0;
        padding: @spaceNorm @spaceNorm3 @spaceNorm @spaceNorm;
      }
    );
  }
  &-SectionHeader {
    height: 25px;
    line-height: 25px;
    background-color: @colorOrange3;
    padding-left: @spaceNorm;
    font-size: @heading-4-size;
    font-weight: bold;
    color: @colorWhite;
    text-transform: uppercase;

    @media (min-width: @screen-lg-min) {
      border-radius: @borderRadiusXs;
    }
  }
  &-SectionBody {
    padding: @spaceNorm @spaceNorm 0 @spaceNorm;
  }
  &-Entry {
    .list-card();
    position: relative;
    padding: @spaceSm @spaceNorm;

    .responsive-md-vs-lg(
      @md-and-smaller: {
        margin: @spaceNorm @spaceNorm3 @spaceNorm @spaceNorm;
      },
      @lg-and-bigger: {
        margin: @spaceNorm @spaceXl @spaceNorm @spaceNorm2;
      }
    );

    &:hover {
      .list-card-hover();
    }
    &:active {
      .list-card-active();
    }

    .LabelWithValue {
      .responsive-md-vs-lg(
        @md-and-smaller: {
          .space-between(@spaceSm, vertical);
        },
        @lg-and-bigger: {
          .space-between(@spaceNorm0_5, vertical);
        }
      );

      .label {
        margin-bottom: @spaceXxs;
      }
      .text {
        font-size: @font-size-sm-plus;
        &.nl-as-newline {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre;
        }
      }
    }

    .responsive-md-vs-lg(
      @md-and-smaller: {
        height: 120px;
  
        .cols-1-2 {
          width: 60%;
        }
        .col-1 {
          width: 100%;
          margin-bottom: @spaceSm;
        }
        .col-2 {
          width: 100%;
        }
        .col-3 {
          width: 40%;
        }
      },
      @lg-and-bigger: {
        height: 80px;
  
        .cols-1-2 {
          width: 72.5%;
        }
        .col-1 {
          flex: 1;
          min-width: 0;
        }
        .col-2 {
          flex: 1;
          min-width: 0;
        }
        .col-3 {
          width: 27.5%;
        }
      }
    );

    > .ant-row {
      width: 100%;
    }

    .control-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: @screen-md-max) {
        position: absolute;
        top: @spaceSm;
        right: @spaceSm;
      }

      @{fa-selector} {
        color: @colorRed;
        .space-between(@spaceNorm1_5, vertical);
      }
    }
  }
}

.AddressBookForm {
  .TemplateDrawerForm-Content {
    > .TwoLines {
      display: none;
    }
  }

  .TemplateDrawerForm-Title {
    @media (min-width: @screen-lg-min) {
      margin-bottom: @spaceNorm0_5;
    }
    @media (max-width: @screen-md-max) {
      margin-bottom: @spaceMd;
    }
  }

  .TemplateDrawerForm-Controls {
    margin-bottom: @spaceMd;
  }
}

.AddressBook-ControlLinks {
  height: 0;
  display: flex;
  align-items: flex-end;

  .responsive-md-vs-lg(
    @md-and-smaller: {
      padding-top: 15px;
    },
    @lg-and-bigger: {
      padding-top: 10px;
    }
  );

  .ant-btn@{btn-default-selector} {
    color: @colorRed;

    .def-btn-disabled({
      border: 1px solid @colorGrey8_1;
      color: @colorRed;
      opacity: 0.25;
    });
  }
  .ant-btn {
    > @{fa-selector} {
      &:first-child {
        margin-right: @spaceXs;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

.AddressBookEntryInvalidDialog.SimpleDialog {
  .ant-modal-content {
    min-height: 140px;
    height: 140px;
  }

  .ant-modal-body {
    padding: @spaceNorm2_5 @spaceNorm1_5;
  }

  h1.ant-typography {
    line-height: 1;
    margin-bottom: @spaceSm;
  }
}

.AddressBook__HeaderRightContent {
  margin-right: @spaceNorm3;

  &.disabled {
    opacity: 0.35;
    pointer-events: none;
  }
}

.AddressBookNoDataDialog.SimpleConfirmDialog.SimpleDialog {
  .ant-modal-body {
    padding-left: @spaceNorm2;
    padding-right: @spaceNorm2;
  }

  h1.ant-typography {
    line-height: 31px;
  }

  span.ant-typography {
    margin-bottom: @spaceNorm;
  }

  .ant-btn {
    height: 30px;
  }

  .ant-btn.ant-btn-background-ghost {
    width: 75px;
  }

  .ant-btn.btn-blue {
    width: 100%;
    border-radius: @borderRadiusSm;
    &,
    &:active,
    &:hover {
      background-color: @colorGreyBluish3;
      border-color: @colorGreyBluish3;
      color: @colorWhite;
    }
  }
}
