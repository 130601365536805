.TemplatesPopup() {
  > .ant-popover-content > .ant-popover-inner {
    box-shadow: 0 0 35px @colorGrey6;
  }
}

.TemplatesListPopup {
  @item-height: 70px;

  .TemplatesPopup();
  width: 300px;

  > .ant-popover-content > .ant-popover-inner {
    background-color: @colorWhite;
    overflow: hidden;
    > .ant-popover-inner-content {
      padding: 0;
      background-color: @colorWhite;

      > .Scrollbar {
        height: (4 * @item-height) !important;
      }
    }
  }

  &-Label {
    .flex-center();
    @media (max-width: @screen-md-max) {
      color: @colorRed;
      font-weight: 500;

      @{fa-selector} {
        font-size: 18px;
        margin-left: @spaceSm;
      }
    }
    @media (max-width: @screen-sm-max) {
      font-size: @font-size-sm-plus;
    }
  }

  .TemplatesList-Header {
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 @spaceNorm;
    background-color: @colorWhite3;
    border: 1px solid @colorWhite;
    box-shadow: 0 3px 10px rgba(@colorGreyBluish3, 0.5);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 6px;
      border-top: 1px solid @colorWhite;
    }

    .TemplatesList-HeaderItem {
      font-size: @heading-4-size;
      font-weight: 300;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @{fa-selector} {
        margin-left: @spaceNorm;

        &.active {
          font-size: 18px;
          color: @colorRed;
        }

        &.fa-signal {
          font-size: 11px;
          transform: rotateZ(90deg);
        }
      }
    }
  }

  .TemplatesList-NoItems {
    padding: @spaceNorm;
    display: flex;
    align-items: center;

    @{fa-selector} {
      margin-right: @spaceNorm;
      color: @colorOrange3;
    }

    > div {
      font-family: @fontCondensed;
      font-weight: bold;
    }
  }

  .TemplatesList-Item {
    position: relative;
    height: @item-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 @spaceNorm;
    cursor: pointer;

    &.active {
      border: 2px solid @colorBlue2;
    }
    &.disabled {
      opacity: 0.5;
    }

    &:nth-child(odd) {
      background-color: @colorGrey10;
    }
    &:hover {
      background-color: @colorWhite6;
    }
    &:active {
      background-color: @colorGrey9_4;
    }

    .TemplatesList-ItemLabel {
      position: relative;
      line-height: 17px;

      padding: @spaceNorm;
      &.has-popup {
        padding: 0 @spaceNorm2;
      }

      > .text {
        font-family: @fontCondensed;
        font-weight: bold;
      }

      @{fa-selector} {
        color: @colorRed;
      }

      .to-left,
      .to-right {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
      .to-left {
        left: 0;
      }
      .to-right {
        right: 0;
      }
      .SpinnerContainer {
        .to-right();
        width: auto;
      }
    }

    .TemplatesList-ItemPreview {
      width: 100%;
      height: 60px;
      padding: 0 @spaceNorm0_5;

      .ant-popover-content .ant-popover-inner {
        background-color: @colorWhite;
        border-radius: @borderRadiusXs;
        box-shadow: 0 0 20px rgba(@colorGreyBluish, 0.75);
      }
      .ant-popover-inner-content {
        display: flex;
        padding: @spaceNorm0_5 @spaceNorm0_5 0 @spaceNorm0_5;
        cursor: pointer;
      }

      .LabelWithValue {
        margin-bottom: @spaceNorm0_5;
        > .label {
          margin-bottom: @spaceXxs;
        }
      }

      .col-1 {
        .Flex1();
        margin-right: @spaceSm;
      }
      .col-2 {
        .Flex1();
      }
    }
  }
}

.SaveTemplatePopup {
  .TemplatesPopup();
  width: 280px;

  .ant-popover-inner-content {
    padding: @spaceNorm @spaceNorm2;
  }

  > .ant-popover-content > .ant-popover-inner {
    background-color: @colorWhite3;
  }

  h4.ant-typography {
    font-weight: 400;
    margin-bottom: @spaceNorm;
  }

  .control-buttons {
    > .ant-col {
      line-height: 16px;
    }

    .InlineLink {
      color: @colorRed;
      font-weight: 500;

      &.cancel-button {
        // Firefox renders in a buggy way if a parent has underline and a child contains text
        > * {
          text-decoration: underline;
        }
      }

      @{fa-selector} {
        margin-right: @spaceXs;
      }
    }
  }
}

.TemplateOperationWarning-Text {
  font-style: italic;
  font-weight: 500;
  text-align: left;
  font-size: @font-size-lg;
}
.TemplateOperationWarning-Name {
  font-size: @font-size-lg;
  line-height: 19px;
  font-weight: bold;
  color: @colorOrange3;
}

.TemplateDrawerForm {
  .absolute(0);
  z-index: 2;
  background-color: @colorWhite3;
  padding-top: @spaceNorm2;
  padding-bottom: @spaceNorm;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0px;
    background-color: @colorWhite3;
  }

  &-Content {
    position: relative;
    height: 100%;

    @media (min-width: @screen-lg-min) {
      width: 560px;
      margin: auto;
    }
    @media (max-width: @screen-md-max) {
      margin: 0 @spaceNorm;
    }

    > .TwoLines {
      margin: @spaceNorm 0;
    }
  }

  &-Title {
    margin-top: @spaceNorm;
    font-weight: 300;

    @media (min-width: @screen-lg-min) {
      font-size: @heading-1_5-size;
    }
    @media (max-width: @screen-md-max) {
      font-size: @heading-3-size;
    }
  }

  &-Controls {
    .InlineLink {
      color: @colorRed;
      font-weight: 500;

      // Firefox renders in a buggy way if a parent has underline and a child contains text
      > * {
        text-decoration: underline;
      }
      > @{fa-selector} {
        text-decoration: none;
        margin-top: -1px;
      }
    }

    .IconButton {
      color: @colorRed;
      font-size: @font-size-base;
      margin-left: @spaceNorm;
    }

    > .ant-col {
      display: flex;
      align-items: center;

      > * {
        .space-between(@spaceNorm, horizontal);
      }
    }

    .ant-btn {
      font-weight: 500;
    }
  }
}
