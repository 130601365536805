.SimpleTable {
  .row() {
    display: flex;
    align-items: center;
    padding: 0 @spaceNorm;
  }

  .SimpleTable-Head {
    .row();
  }

  .SimpleTable-Body {
    .SimpleTable-Row {
      .row();
    }
  }

  .SimpleTable-Cell {
    padding: 0 @spaceNorm;
    min-width: 0;
  }
}
