.ant-transfer {
  height: 100%;

  .ant-form-item-row {
    height: 100%;
  }

  .ant-transfer-list-body-customize-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
  }

  .ant-transfer-list-body {
    flex-grow: 1;
  }

  .ant-transfer-operation {
    align-self: flex-start;

    .ant-btn.ant-btn-primary {
      box-shadow: 0 2px 10px #1f262e;
      width: 40px;
      height: 28px;
      display: flex;
      margin-bottom: 10px;

      &,
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: @colorGreyBluish7_4;
        border: 1px solid @colorGreyBluish7_4;
      }

      &[disabled] {
        opacity: 0.5;
      }

      .anticon {
        display: none;
      }

      .fa-chevron-left,
      .fa-chevron-right {
        margin: 0;
      }
    }
  }
}

.ant-transfer-list-header {
  background: @colorGreyDark3;
  border-color: @colorBlack2;
  color: @colorGreyBluish8;
  flex-direction: row-reverse;
  height: 30px;
  padding: 0 10px;
}

.ant-transfer-list {
  border-color: @colorGreyDark3 !important;
  min-width: 0;
  width: 330px !important;
  flex-basis: 0 !important;

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: @colorGreyBluish3;
      border-color: @colorGreyBluish3;
    }
  }
}

.ant-transfer-list-header-title {
  text-align: left;
}

.ant-transfer-list-body-search-wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: @colorGreyDark2_7;
  padding: 0 10px;
  border-top: 1px solid @colorGrey3_5;

  .ant-transfer-list-search {
    .ant-input {
      background-color: @colorGreyDark1_1;
      border-radius: 4px;
      font-family: @fontStd;
      font-weight: 500;
      font-size: 16px;
      color: @colorGrey7_1 !important;

      &,
      &:hover,
      &:active,
      &:focus {
        border-color: @colorGreyDark1_3;
        box-shadow: none;
      }
    }

    &,
    ::placeholder {
      color: @colorGrey11;
      font-weight: 500;
    }

    .ant-input-prefix {
      .anticon,
      .anticon-search {
        color: @colorGrey11;
        font-size: 18px;
      }
    }

    .ant-input-suffix {
      .anticon {
        color: @colorGrey11;
        font-size: 12px;
      }
    }
  }
}

.transfer-header {
  color: @colorGreyBluish3;
  box-shadow: fade(#000, 35%) 0px 1px 10px inset;
  line-height: 30px;
  padding-left: 10px;
  font-family: @fontCondensed;
  font-weight: 700;
  background-color: @colorGreyDark2_7;
  border-bottom: 1px solid @colorGrey3_5;
  border-top: 1px solid @colorBlack2;

  .ant-checkbox {
    margin-right: 15px;
  }

  .ant-checkbox-wrapper {
    color: @colorGreyBluish3;
    font-size: 14px;
    font-weight: 700;
  }
}

.transfer-row {
  padding: 0 20px;
  color: @colorGreyBluish3_2;
  font-family: @fontStd;
  font-weight: 400;
  line-height: 39px;

  &:nth-child(odd) {
    border-bottom: 1px solid @colorBlack2;
  }
  &:nth-child(even) {
    border-bottom: 1px solid @colorGreyDark2_8;
  }

  .ant-checkbox {
    margin-right: 20px;
  }

  .ant-checkbox-wrapper {
    color: @colorGreyBluish3_2;
    font-size: 14px;
    font-weight: 400;
  }
}

.ant-transfer-list-header-title {
  text-transform: uppercase;
  color: @colorGreyBluish8;
  font-family: @fontCondensed;
  font-weight: 700;
}

.ant-transfer-list-header-selected {
  color: @colorGreyBluish7_4;
  font-family: @fontCondensed;
  font-weight: 700;
  text-transform: uppercase;
}

.solid-divider {
  background-color: @colorGreyDark2_8;
  height: 10px;
  border-top: 1px solid @colorGrey3_5;
  border-bottom: 1px solid @colorBlack2;
  position: relative;
  flex-shrink: 0;

  &::before {
    content: ' ';
    border-top: 1px solid @colorBlack2;
    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
  }
}

.ant-transfer {
  height: 100%;
}

.transfer-section-auto-height {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .Scrollbar-NoHorizontal .Scrollbar-View {
    max-height: 100% !important;
  }
}

each(range(40px, 240px, 40), {
  .transfer-section-@{index} .transfer-section-scrollbars {
    height: @value;
  }
});

.transfer-section-scrollbars {
  overflow: hidden;
  display: flex;
  flex-grow: 1;

  .Scrollbar-NoHorizontal {
    flex-grow: 1;
  }
}

.transfer-section-fixed {
  display: flex;
  flex-direction: column;
}
