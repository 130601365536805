.def-btn-disabled(@body) {
  &.ant-btn-disabled,
  &.disabled,
  &[disabled] {
    &,
    &:hover,
    &:focus {
      @body();
    }
  }
}

@btn-default-selector: ~':not(.ant-btn-primary):not(.ant-btn-background-ghost):not(.ant-btn-dashed):not(.ant-btn-link)';

.ant-btn {
  .flex-center();
  font-family: @fontStd;
  font-weight: 500;
  padding-left: @spaceSm;
  padding-right: @spaceSm;
  text-shadow: none;
  box-shadow: none;
  line-height: 1;
  height: 30px;
  font-size: @font-size-base;

  @{fa-selector} {
    font-size: @font-size-lg;
    &:first-child {
      @media (min-width: @screen-md-min) {
        margin-right: @spaceSm;
        &.margin-sm {
          margin-right: @spaceXs;
        }
      }
      @media (max-width: @screen-sm-max) {
        margin-right: @spaceXxs;
      }
    }
    &:last-child {
      @media (min-width: @screen-md-min) {
        margin-left: @spaceSm;
        &.margin-sm {
          margin-left: @spaceXs;
        }
      }
      @media (max-width: @screen-sm-max) {
        margin-left: @spaceXxs;
      }
    }
  }

  .ant-btn-loading-icon {
    display: none;
  }
}
.ant-btn-sm {
  height: 26px;
  font-size: @font-size-sm-plus;
  padding-left: @spaceNorm1_5;
  padding-right: @spaceNorm1_5;

  &.ant-btn-primary {
    font-size: @font-size-base;
    padding-left: @spaceNorm2;
    padding-right: @spaceNorm2;
  }
}
.ant-btn-lg {
  height: 40px;
  font-size: @font-size-lg-minus;
}

.ant-btn.ant-btn-primary {
  font-weight: 900;
  text-shadow: none;
  box-shadow: none;
  background-color: @colorRed;

  &.ant-btn-background-ghost {
    font-weight: 500;
  }

  &:hover {
    border-color: @colorRed6;
    background-color: @colorRed6;
  }
  &:active,
  &:focus {
    border-color: @colorRed7;
    background-color: @colorRed7;
  }

  .def-btn-disabled({
    background-color: @colorGrey8_1;
    border-width: 0;
    color: @colorGrey9_9;
    box-shadow: none;
  });
}

@{btn-default-selector}.ant-btn {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: @colorWhite;
    border: 1px solid @colorGrey8_1;
    color: @colorGrey3_5;
  }

  .def-btn-disabled({
    border: 1px solid rgba(@colorGrey8_1, 0.5);
    color: rgba(@colorGrey3_5, 0.5);
  });
}

.ant-btn.ant-btn-background-ghost {
  &,
  &:hover,
  &:active,
  &:focus {
    border: 1px solid @colorGrey8_1;
    color: @colorGrey3_5;
    background-color: transparent;
  }

  .def-btn-disabled({
    border: 1px solid rgba(@colorGrey8_1, 0.5);
    color: rgba(@colorGrey3_5, 0.5);
  });
}

.ant-btn.ant-btn-primary.ant-btn-background-ghost {
  border-color: @colorRed;
  color: @colorRed;
  &:hover {
    border-color: @colorRed6;
    color: @colorRed6;
  }
  &:active,
  &:focus {
    border-color: @colorRed7;
    color: @colorRed7;
  }

  .def-btn-disabled({
    border-color: rgba(@colorGreyBluish, 0.5);
    color: rgba(@colorRed, 0.5);
    box-shadow: none;
  });
}

.ant-btn.ant-btn-loading {
  &,
  &:active,
  &:focus,
  &[disabled] {
    display: block;
    cursor: not-allowed;
    transition-property: none;
    box-shadow: none;

    &::before {
      display: none;
    }

    &.ant-btn-primary {
      background-color: @colorRed;
      color: @colorWhite;
      border-width: 0;
    }
  }
}
