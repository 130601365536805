.SpinnerContainer {
  display: flex;
  width: 100%;
  justify-content: center;

  &.SpinnerContainer-square {
    align-items: center;

    // This is to make it square - padding percents are computed relative to parent's width
    &::before {
      content: '';
      padding-top: 100%;
    }
  }

  &.SpinnerContainer-row {
    align-items: flex-start;
    height: auto;
  }

  &.SpinnerContainer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
  }
}

@dotAnimationDuration: 2s;

@keyframes threeDotsAnimation {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.dot(@size, @index) {
  height: @size;
  width: @size;
  border-radius: 100%;
  background-color: currentColor;

  animation-name: threeDotsAnimation;
  animation-duration: @dotAnimationDuration;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-delay: (@dotAnimationDuration / 3) * @index;
}

.dots-mixin(@size, @spacing) {
  span {
    .dot(@size, 1);
    margin: 0 @spacing;
  }
  &::before {
    content: ' ';
    .dot(@size, 0);
  }
  &::after {
    content: ' ';
    .dot(@size, 2);
  }
}

.ThreeDotsSpinner {
  display: inline-flex;
  align-items: flex-end;
  margin-left: @spaceSm;

  .three-dots-xs &,
  &.size-xs {
    .dots-mixin(4px, 2px);
  }
  .three-dots-sm &,
  &.size-sm {
    .dots-mixin(5px, 4px);
  }
  &,
  .three-dots-md &,
  &.size-md {
    .dots-mixin(8px, 6px);
  }
  .three-dots-lg &,
  &.size-lg {
    .dots-mixin(10px, 8px);
  }
}
