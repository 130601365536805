.StickyElement {
  &.active > &-Inner {
    position: fixed;
    z-index: 1;
  }
}

.StickyElement.active > .StickyElement-Inner {
  background-color: @colorWhite2;
  // This is so that shadows of the scroll content from below are covered by the background-like shadow
  &::before,
  &::after {
    content: ' ';
    position: absolute;
    height: 100%;
    top: 0;
    width: 10px;
  }
  &::before {
    box-shadow: inset -10px 0 0 10px @colorWhite2;
    left: -10px;
  }
  &::after {
    box-shadow: inset 10px 0 0 10px @colorWhite2;
    right: -10px;
  }
}

.StickyElement-Content {
  position: relative;
  z-index: 1;
}
