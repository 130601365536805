.EditShipmentPage {
  max-width: 1024px;
  margin: 0 auto;

  h2.ant-typography {
    font-family: @fontCondensed;

    @media (min-width: @screen-lg-min) {
      font-size: @heading-2_5-size;
    }
    @media (min-width: @screen-lg-min) and (max-width: @screen-md-max) {
      font-size: @heading-3_5-size;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      font-size: @heading-4-size;
    }
    @media (max-width: @screen-sm-max) {
      font-size: @font-size-lg;
    }

    margin-bottom: 0;
  }

  .EditShipmentPage__TopButtons {
    display: flex;

    > * {
      .space-between(@spaceNorm2, horizontal);
    }

    > .ant-btn:first-child {
      width: 80px;
      font-size: @font-size-sm-plus;
    }
    > .ant-btn:last-child {
      width: 200px;
      font-size: @font-size-base;
    }
  }
}

.EditShipment-CancelShipment {
  font-weight: 700;
  color: @colorRed;
  line-height: 1;

  @media (min-width: @screen-md-min) {
    font-size: @font-size-base;
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    font-size: @font-size-sm-plus;
  }
  @media (max-width: @screen-xs-max) {
    font-size: @font-size-xs;
  }

  // Firefox renders in a buggy way if a parent has underline and a child contains text
  > * {
    text-decoration: underline;
  }

  h2.ant-typography + & {
    .absolute-middle-to-right();
    right: -@spaceNorm2;
    font-size: @font-size-sm-plus;
  }
}
