// -enter and -exit are there for React Transitions, -appear and -leave are there for Ant Motion
.define-animation(
  @name,
  @animated-props,
  @start,
  @end,
  @easing: ease-in-out,
  @duration: @animationDurationMd
) {
  .@{name}-enter,
  .@{name}-appear {
    @start();
  }

  .@{name}-enter.@{name}-enter-active,
  .@{name}-appear.@{name}-appear-active {
    @end();
    transition: @animated-props @duration @easing;
  }

  .@{name}-exit,
  .@{name}-leave {
    @end();
  }

  .@{name}-exit.@{name}-exit-active,
  .@{name}-leave.@{name}-leave-active {
    @start();
    transition: @animated-props @duration @easing;
  }
}

.define-keyframes-animation(
  @name,
  @animation-name,
  @duration: @animationDurationMd
) {
  .@{name}-enter {
    animation-name: @animation-name;
    animation-duration: @duration;
    animation-play-state: running;
  }

  .@{name}-enter.@{name}-enter-active {
    animation-name: @animation-name;
    animation-duration: @duration;
    animation-play-state: running;
  }

  .@{name}-exit,
  .@{name}-leave {
    animation-name: @animation-name;
    animation-duration: @duration;
  }

  .@{name}-exit.@{name}-exit-active,
  .@{name}-leave.@{name}-leave-active {
    animation-name: @animation-name;
    animation-duration: @duration;
    animation-fill-mode: forwards;
  }
}

.define-animation(~'from-right', transform, {transform: translateX(100%);}, {transform: none;});
.define-animation(~'from-left', transform, {transform: translateX(-100%);}, {transform: none;});
.define-animation(~'from-top', transform, {transform: translateY(-100%);}, {transform: none;});
.define-animation(~'from-bottom', transform, {transform: translateY(100%);}, {transform: none;});
.define-animation(~'fade-from-left', all, {transform: translateX(-100%); opacity: 0;}, {transform: none; opacity: 1;}, @duration: @animationDurationLg);
.define-animation(~'scale-up', transform, {transform: scale(0);}, {transform: scale(1);});
