.ant-form-item.ZipCodeInput {
  .ant-form-item-explain {
    width: 0;
    white-space: nowrap;
  }
}

.InputText.ZipCodeInput {
  width: 130px;
}
