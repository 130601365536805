.ant-steps.ant-steps-horizontal {
  @circle-size: 30px;
  @circle-padding: 5px;
  @outer-circle-size: @circle-size + @circle-padding * 2;
  @box-shadow: 0 4px 6px rgba(@colorGrey8_7, 0.65);
  @border-color: @colorGrey7_7;
  @background-color: @colorGrey9_9;
  @inner-border-color: @colorRed5;

  padding-top: @circle-padding;

  &:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) {
    .ant-steps-item {
      padding-top: 0;
    }
  }

  .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    left: 0;

    @{fa-selector} {
      width: @circle-size;
      height: @circle-size;
      margin: 1px;
      background-image: linear-gradient(180deg, @colorRed3 0%, @colorRed4 100%);
      border: 1px solid @inner-border-color;
      border-radius: 100%;
      .flex-center;
      font-size: 16px;
      color: @colorWhite;

      &.size-xs {
        font-size: 12px;
      }
      &.size-sm {
        font-size: 16px;
      }
      &.size-md {
        font-size: 20px;
      }
      &.size-lg {
        font-size: 24px;
      }
    }
  }

  .ant-steps-item-icon {
    position: relative;
    &::before {
      content: ' ';
      .absolute-middle-center();
      width: @outer-circle-size;
      height: @outer-circle-size;
      background-color: @background-color;
      border-radius: 100%;
      border: 1px solid @border-color;
      box-shadow: @box-shadow;
    }
  }
  .ant-steps-icon {
    * > {
      display: block;
    }
  }
  &,
  .ant-steps-item-wait,
  .ant-steps-item-process {
    .ant-steps-item-tail {
      top: 7px;
      height: 18px;
      border: 1px solid @border-color;
      &::before {
        content: ' ';
        .absolute(@vertical: 0, @horizontal: 16px);
        background-color: @background-color;
        z-index: 1;
        box-shadow: @box-shadow;
      }
      &::after {
        .absolute(@vertical: 3px, @horizontal: 16px);
        background-color: @background-color;
        z-index: 2;
        border-radius: 0;
        height: auto;
        width: auto;
      }
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    left: 12px;
    right: 13px;
    border-top: 1px solid @inner-border-color;
    border-bottom: 1px solid @inner-border-color;
    background-color: @colorRed;
  }
  .ant-steps-item-title {
    font-weight: bold;
    line-height: 1.3;
    font-family: @fontCondensed;
    font-size: @font-size-lg;
    .nl-as-newline();
  }

  &.no-numbers {
    .ant-steps-icon {
      font-size: 0;
    }
  }

  &.aligned-labels {
    @side-part: 37px;
    width: initial;
    margin-left: -@side-part;
    margin-right: -@side-part;
    .ant-steps-item {
      &:first-child .ant-steps-item-content {
        padding-left: @side-part;
        text-align: left;
        .ant-steps-item-title {
          transform: translateY(-100%) translateY(-@outer-circle-size - 3px);
        }
      }
      &:last-child .ant-steps-item-content {
        padding-right: @side-part;
        text-align: right;
        .ant-steps-item-title {
          transform: translate(-100%, -100%)
            translateY(-@outer-circle-size - 3px);
        }
      }
    }
  }

  &.labels-in-middle {
    .ant-steps-item-content {
      .flex-center();
      margin-top: 6px;
      height: 40px;
      .ant-steps-item-title {
        line-height: 20px;
      }
    }
  }

  &.title-above {
    .ant-steps-item-title {
      position: absolute;
      transform: translate(-50%, -100%) translateY(-@outer-circle-size - 3px);
    }
  }
}
