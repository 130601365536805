a {
  &,
  .ant-typography & {
    &,
    &:link,
    &:visited {
      color: inherit;
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.Clickable {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.CommonLabelValue-Label {
  color: @colorGreyBluish2;
  text-transform: uppercase;
  line-height: 1;
  min-height: 1em;
  font-size: @font-size-sm;
}

.CommonLabelValue-Text {
  font-size: @font-size-sm-plus;
  font-weight: bold;
  line-height: 1.25;
}

.LabelWithValue {
  &.nowrap {
    .label,
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .text {
      flex: 1;
    }
    .text.nl-as-newline {
      .nl-as-newline();
    }
  }
  &.label-nowrap {
    .label {
      white-space: nowrap;
    }
  }

  &.with-colon {
    .label::after {
      content: ':';
    }
  }

  .label {
    .CommonLabelValue-Label();

    &.size-sm {
      width: 70px;
    }
    &.size-md {
      width: 110px;
    }
    &.size-lg {
      width: 140px;
    }
    &.size-full {
      width: 100%;

      & + .text {
        width: 100%;
        flex: initial;
      }
    }
    &.size-auto {
      width: auto;
      margin-right: @spaceXs;
    }
  }
  .text {
    .CommonLabelValue-Text();
  }

  &.size-xs {
    .label {
      font-size: @font-size-xs-minus;
      font-weight: 500;
    }
    .text {
      font-size: @font-size-sm-minus;
      font-weight: 500;
    }
  }
  &,
  &.size-sm {
    .label {
      font-size: @font-size-sm;
    }
    .text {
      font-size: @font-size-sm-plus;
    }
  }
  &.size-md {
    .label {
      font-size: @font-size-sm;
    }
    .text {
      font-size: @font-size-base;
    }
  }
  &.size-lg {
    font-family: @fontCondensed;
    font-weight: bold;
    .label {
      font-size: @font-size-sm;
      line-height: 1.5;
    }
    .text {
      font-size: @font-size-lg;
    }
  }
}

.BlockLabelWithValue {
  .label {
    font-family: @fontCondensed;
    font-weight: bold;
    text-transform: uppercase;
  }
  .text {
    flex: 1;
    margin-top: @spaceXs;
    line-height: 1.23;
    &,
    & > * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.size-sm {
    .label {
      font-size: @font-size-sm-plus;
    }
    .text {
      font-size: @font-size-sm-plus;
    }
  }
}

.Typography-LabelCategory {
  font-family: @fontCondensed;
  font-weight: 700;
  color: @colorGreyBluish2;
}

.LabelRow {
  border-bottom: 1px solid @colorGrey8;
  padding: @spaceSm 0;
  &:last-child {
    border-bottom-width: 0;
  }
  &.no-border {
    border-bottom-width: 0;
    padding-bottom: @spaceXs;

    + .LabelRow {
      padding-top: @spaceXs;
    }
  }
}

.DisabledBlock {
  pointer-events: none;
  cursor: not-allowed;
}
.DisabledBlock--50 {
  opacity: 0.5;
}
.DisabledBlock--35 {
  opacity: 0.35;
}
