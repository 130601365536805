@import './newShipment/newShipmentDialogs.less';
@import './newShipment/newShipmentButtons.less';
@import './newShipment/newShipmentCards.less';
@import './newShipment/newShipmentOrderPreview.less';
@import './newShipment/newShipmentServiceInformation.less';
@import './newShipment/newShipmentPackages.less';
@import './newShipment/newShipmentPieces.less';

@import './newShipment/addressBook.less';
@import './newShipment/shipmentTemplates.less';

#NewShipmentPage {
  .BaseLoggedPage-InnerContent {
    @media (max-width: @screen-md-max) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.NewShipmentPage__PartnerAccessTopBar {
  margin-bottom: @spaceNorm;
  height: 20px;
  .border-radius(@borderRadiusXs, top);
  background-color: @colorBlue2;
  color: @colorWhite;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  line-height: 20px;
  text-transform: uppercase;
}
