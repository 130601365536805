.PieceListDrawer {
  .PieceList-Header {
    height: 40px;
    background-color: @colorGrey9_6;
    padding: 0 @spaceNorm;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .LabelWithValue.size-lg .label {
      font-family: @fontStd;
      font-weight: 500;
    }
    .IconButton {
      color: @colorRed;
      display: block;
      font-size: @heading-4-size;
    }
  }
  .PieceList-EditingList {
    padding: @spaceNorm2 @spaceNorm2 @spaceNorm0_5 @spaceNorm2;

    > * {
      .space-between(@spaceNorm2, vertical);
    }
  }
  .NewShipmentBottomControls {
    padding: @spaceNorm @spaceNorm2;
    .ant-btn-sm.ant-btn-primary {
      width: 200px;
    }
  }
  .TwoLines {
    margin-bottom: @spaceNorm2;
  }
  .PieceList-PreviewRows {
    padding: 0 @spaceNorm;
    padding-bottom: @spaceXl;

    > .TwoLinesSeparator.wide {
      margin: 0 -@spaceNorm;
    }
  }
  .PieceList-PreviewRow {
    display: flex;
    align-items: center;

    > .ant-row {
      margin-bottom: -@spaceNorm;
      > .ant-col {
        margin-bottom: @spaceNorm;
      }
    }

    @media (min-width: @screen-lg-min) {
      padding: @spaceNorm;
      padding-left: 0;
    }
    @media (max-width: @screen-md-max) {
      padding: @spaceNorm 0;
    }

    .number-in-circle {
      margin-right: @spaceNorm2;
    }
  }
}
.PieceList-Controls {
  width: 20px;
  height: 60px;
  border-radius: @borderRadiusSm;
  background-color: @colorRed;

  .IconButton {
    font-size: 14px;
    display: block;
    color: @colorWhite;
  }
}
.PieceList-Envelope {
  background-color: @colorWhite2;
  border: 2px solid @colorWhite;
  border-radius: @borderRadiusXs;
  box-shadow:
    0 -1px 0 @colorWhite,
    0 1px 0 rgba(@colorGreyBluish, 0.25),
    1px 1px 6px @colorGrey8_1;

  > .ant-row {
    margin-bottom: -@spaceNorm;
    > .ant-col {
      margin-bottom: @spaceNorm;
    }
  }

  @media (min-width: @screen-lg-min) {
    padding: @spaceNorm1_5 @spaceNorm2;
  }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    padding: @spaceNorm @spaceNorm2;
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    padding: @spaceNorm;
  }
  @media (max-width: @screen-xs-max) {
    padding: @spaceNorm @spaceSm;
  }
}
