.FileUpload__Container {
  > * {
    display: block;
  }
}

.FileUpload.ant-upload.ant-upload-drag {
  border-radius: 0;
  border: 2px dashed @colorGrey8_1;
  background-color: @colorWhite;

  .ant-upload.ant-upload-btn {
    padding: 0;
    display: block;
  }

  .ant-upload-drag-container {
    display: block;
    padding: @spaceNorm5 0;
    .nl-as-newline();
    font-size: @heading-4-size;
    line-height: 21.5px;
  }

  + .ant-upload-list {
    margin-top: @spaceNorm2;
    &:empty {
      margin-top: 0;
    }

    .ant-upload-list-text-container {
      .space-between(@spaceNorm, vertical);
    }
  }
}

.FileUploadItemPreview {
  display: flex;
  align-items: center;

  .FileUploadItemPreview__Icon {
    width: @spaceNorm2;

    &.FileUploadItemPreview__Icon--Success {
      color: @colorLightGreen2;
    }
    &.FileUploadItemPreview__Icon--Error {
      color: @colorRed;
    }

    .ThreeDotsSpinner {
      margin-left: 0;
    }
  }

  &.FileUploadItemPreview--HasError {
    .FileUploadItemPreview__DocumentName {
      color: @colorRed;
    }
  }

  .FileUploadItemPreview__DocumentName {
    flex: 1;
    font-size: @font-size-lg;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.FileUploadItemPreview--WithSelect {
    > * {
      .space-between(@spaceNorm, horizontal);
    }
    > .FileUploadItemPreview__Icon {
      margin-right: 0;
    }
  }
}

.FileUploadItemPreview__Error {
  color: @colorRed;
  line-height: 1;
  padding-left: @spaceNorm2;
}
