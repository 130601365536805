.AdminConsoleList__TableContainer {
  position: relative;
}

.AdminConsoleList__Pagination {
  position: absolute;
  left: 0;
  top: -20px;
  font-size: @font-size-sm;
  color: @colorGreyBluish3_2;
}

.AdminConsoleList__Column {
  overflow: hidden;
  text-overflow: ellipsis;
}

.AdminConsoleList__TableRow {
  color: @colorGreyBluish3_2;
  padding: 0 @spaceNorm2;

  > .ant-row {
    height: 40px;
  }

  &:nth-child(odd) {
    border-bottom: 1px solid @colorBlack2;
  }

  &:nth-child(even) {
    border-bottom: 1px solid @colorGreyDark2_7;
  }

  &:last-child {
    border-bottom-width: 0;
  }

  &:hover {
    color: @colorOrange1;
  }

  &:active {
    color: @colorOrange3;
  }
}

.AdminConsoleList__TableBody--Empty {
  font-size: @heading-3-size;
  font-family: @fontCondensed;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}
.AdminConsoleList__TableBody--Empty--NoFilter {
  color: @colorGreyBluish;
}
.AdminConsoleList__TableBody--Empty--NoData {
  color: rgba(@colorOrange1, 0.85);
}

.AdminConsoleList__FilterSectionContent {
  display: flex;
  align-items: center;
  height: 55px;
  padding-top: 0;
  padding-bottom: 0;

  > * {
    flex: 1;
  }
}

.AdminConsoleList__HeaderColumn {
  display: flex;
  align-items: center;
  gap: @spaceXs;
}

.AdminConsoleList__FilterLabel {
  color: @colorGreyBluish;
  font-weight: 500;
}

.AdminConsoleList__BorderlessSelectCol {
  display: flex;
  align-items: center;
  height: 0;
  overflow: visible;
}
