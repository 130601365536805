.Gauge {
  &-Container {
    position: relative;
  }

  &-Svg {
    display: block;
  }

  &-Shadow {
    .absolute(0);
    border-radius: 50%;
    box-shadow: 0 0 35px rgba(@colorGrey6, 0.45);
  }

  &-ValueText {
    .absolute(@horizontal: 0, @vertical: initial);
    transform: translateY(-50%);
    text-align: center;
    z-index: 2;
    font-size: 12%;
    font-weight: bold;
    letter-spacing: -1px;
    color: @colorGreyDark1;
    line-height: 1;

    .help-text {
      font-size: 62.5%;
      letter-spacing: 0;
    }
  }

  &-AxisLabel {
    position: absolute;
    color: @colorGrey2;
    font-size: 6.75%;
    font-family: @fontCondensed;
    font-weight: bold;
    line-height: 1;
  }
}
