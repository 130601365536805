.CircularProgressbar {
  &-Container {
    position: relative;
    text-align: initial;
  }

  &-TextContainer {
    .absolute(0);
    .flex-center();
    z-index: 2;
    flex-direction: column;
    text-align: center;
  }

  &-Number {
    font-size: 24%;
    font-weight: bold;
    line-height: 1.2em;
    color: @colorGrey3;
  }

  &-Label {
    font-size: 16%;
    line-height: 0.9em;
    color: @colorGreyBluish3;
  }

  &-Graphic {
    position: absolute;
    z-index: 1;
    stroke: @colorRed;
  }

  &-Shadow {
    .absolute(4px);
    z-index: 0;
    border-radius: 50%;
    box-shadow: 0px 2px 20px @colorGrey2;
  }
}
