.ShipmentTemplatesListPopup {
  color: @colorRed;
  font-weight: bold;

  @media (min-width: @screen-sm-min) {
    font-size: @font-size-lg;
  }
  @media (max-width: @screen-xs-max) {
    font-size: @font-size-sm-plus;
  }

  .ShipmentTemplatesListPopup-Name {
    @media (min-width: @screen-md-min) {
      max-width: 280px;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      max-width: 174px;
    }
    @media (max-width: @screen-xs-max) {
      max-width: 119px;
    }
  }
}

.SaveShipmentTemplatePopup-UseDefaultName {
  margin-top: -@spaceNorm0_5;
  font-size: @font-size-sm-minus;
  font-weight: 500;
  color: rgba(@colorRed, 0.5);
  margin-bottom: @spaceNorm0_5;

  // Firefox renders in a buggy way if a parent has underline and a child contains text
  > .InlineLink > * {
    text-decoration: underline;
  }
}

.DeleteShipmentTemplateDialog.ant-modal.SimpleDialog {
  .ant-modal-body {
    display: block;
    padding-left: @spaceNorm2;
    padding-right: @spaceNorm2;
  }
  h1.ant-typography {
    margin-bottom: 0;
  }
  .TwoLines {
    margin: @spaceNorm0_5 -@spaceNorm2 @spaceMd -@spaceNorm2;
  }
}

.ShipmentTemplateForm {
  &-Name {
    font-size: @heading-3_5-size;
    font-weight: 300;
    padding: @spaceNorm0_5 0 @spaceMd 0;
  }
}

.ant-modal.SimpleDialog.ShipmentTemplateChangedDialog {
  position: absolute;
  top: 120px;

  .ant-modal-content {
    width: 300px;
    min-height: 210px;
    border-radius: @borderRadiusSm;
    background-color: @colorWhite3;
    box-shadow: 0 0 50px rgba(@colorGrey6, 0.9);
  }

  .ant-modal-body {
    display: block;
    position: relative;
    padding: @spaceNorm2_5 @spaceNorm @spaceNorm @spaceNorm;
    min-height: 210px;
  }

  h2.ant-typography {
    font-weight: 300;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .TemplateOperationWarning-Name {
    margin-bottom: @spaceNorm;
    text-align: center;
  }

  .ant-btn {
    width: 100%;
  }

  .TwoLines {
    margin-left: -@spaceNorm;
    margin-right: -@spaceNorm;
  }

  @{fa-selector} {
    &.fa-exclamation-triangle {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      font-size: 36px;
      color: @colorOrange3;

      &::after {
        z-index: -1;
        content: '';
        position: absolute;
        top: 6px;
        bottom: 2px;
        right: 10px;
        left: 10px;
        background-color: @colorWhite;
        border-radius: 100%;
      }
    }
  }
}
