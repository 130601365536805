@prefix: Scrollbar;
@reserveOnSides: 20px;
.@{prefix} {
  > .@{prefix}-View {
    // Firefox
    scrollbar-width: 'none';
    // IE10
    -ms-overflow-style: '-ms-autohiding-scrollbar';
    // Others
    & *::-webkit-scrollbar {
      display: 'none';
    }
  }

  &.@{prefix}-SpaceOutside {
    // This is a reserve for shadows and other graphical content on the edge
    margin-left: -@reserveOnSides;
    margin-right: -@reserveOnSides;

    > .@{prefix}-View {
      padding-left: @reserveOnSides;
      padding-right: @reserveOnSides;
    }

    > .@{prefix}-TrackVertical {
      right: @reserveOnSides;
    }
  }

  &.@{prefix}-NoHorizontal > .@{prefix}-View {
    overflow-y: scroll;
    overflow-x: hidden !important;
    margin-bottom: 0 !important;
  }

  &.@{prefix}-ShrinkContent > .@{prefix}-View {
    padding-right: @scrollbarWidth + @spaceXs;
  }

  .@{prefix}-Thumb {
    background-color: @colorGreyBluish4;
  }
  &.Theme-Light .@{prefix}-Thumb {
    background-color: rgba(@colorGreyBluish4, 0.5);
  }

  .@{prefix}-Thumb {
    cursor: pointer;
    border-radius: @borderRadiusMd;
    .DetailPage .DetailPage-InnerContent & {
      @media (max-width: @screen-sm-max) {
        z-index: 2;
      }
    }
  }

  .@{prefix}-TrackVertical {
    right: @spaceNorm0_5;
    bottom: 0;
    top: 0;
    border-radius: 0;
    width: @scrollbarWidth !important;
  }
  &.@{prefix}-VerticalOutside > .@{prefix}-TrackVertical {
    @media (min-width: @screen-md-min) {
      @outsideScrollbarDistance: 6px;
      right: @reserveOnSides - @scrollbarWidth - @outsideScrollbarDistance;
    }
    @media (max-width: @screen-sm-max) {
      @outsideScrollbarDistance: 2px;
      right: @reserveOnSides - @scrollbarWidth - @outsideScrollbarDistance;
    }
    #NewShipmentPage .BaseLoggedPage-InnerContent &,
    .DetailPage .DetailPage-InnerContent & {
      @media (max-width: @screen-md-max) {
        @outsideScrollbarDistance: 2px;
        right: @reserveOnSides - @scrollbarWidth - @outsideScrollbarDistance;
      }
    }
  }

  .@{prefix}-TrackHorizontal {
    right: 0;
    left: 0;
    border-radius: 0;
    height: @scrollbarWidth !important;
  }
  &.Scrollbar-HorizontalTop > .@{prefix}-TrackHorizontal {
    top: 0;
  }
  &.Scrollbar-HorizontalBottom > .@{prefix}-TrackHorizontal {
    bottom: 0;
  }
  &.Scrollbar-NoHorizontal > .@{prefix}-TrackHorizontal {
    visibility: hidden !important;
  }
}

// Hack: https://github.com/malte-wessel/react-custom-scrollbars/issues/127#issuecomment-281445949
::-webkit-scrollbar {
  min-width: 1px;
  min-height: 1px;
}
