.absolute(@horizontal, @vertical) {
  position: absolute;
  top: @vertical;
  bottom: @vertical;
  right: @horizontal;
  left: @horizontal;
}
.absolute(@all: 0) {
  .absolute(@horizontal: @all, @vertical: @all);
}

.border-radius(@size: @borderRadiusSm, @side: all) {
  .border-radius-top() {
    border-top-left-radius: @size;
    border-top-right-radius: @size;
  }
  .border-radius-bottom() {
    border-bottom-left-radius: @size;
    border-bottom-right-radius: @size;
  }
  .border-radius-left() {
    border-top-left-radius: @size;
    border-bottom-left-radius: @size;
  }
  .border-radius-right() {
    border-top-right-radius: @size;
    border-bottom-right-radius: @size;
  }
  .border-radius-inner(top) {
    .border-radius-top();
  }
  .border-radius-inner(bottom) {
    .border-radius-bottom();
  }
  .border-radius-inner(left) {
    .border-radius-left();
  }
  .border-radius-inner(right) {
    .border-radius-right();
  }
  .border-radius-inner(all) {
    .border-radius-top();
    .border-radius-bottom();
  }
  .border-radius-inner(@side);
}

.space-between(@space, @type: horizontal) {
  .space-between-inner(@space, horizontal) {
    margin-right: @space;
    &:last-child {
      margin-right: 0;
    }
  }
  .space-between-inner(@space, vertical) {
    margin-bottom: @space;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .space-between-inner(@space, @type);
}

.text-after(@content) {
  content: @content;
  display: inline-block;
  margin-left: 4px;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
}

.triangle(@color, @width, @height, @direction: up) {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;

  .b(up) {
    border-width: 0 (@width / 2) @height (@width / 2);
    border-bottom-color: @color;
  }
  .b(down) {
    border-width: @height (@width / 2) 0 (@width / 2);
    border-top-color: @color;
  }
  .b(left) {
    border-width: (@width / 2) @height (@width / 2) 0;
    border-right-color: @color;
  }
  .b(right) {
    border-width: (@width / 2) 0 (@width / 2) @height;
    border-left-color: @color;
  }

  .b(@direction);
}

.with-triangle(
  @width: 18px,
  @height: 12px,
  @position,
  @border-width: 1px,
  @border-color,
  @background-color,
  @direction: up,
  @outer-styles: {},
  @apply-own-styles: true
) {
  .own-styles(true) {
    & {
      position: relative;
      background-color: @background-color;
      border: @border-width solid @border-color;
    }
  }
  .own-styles(false) {
  }
  .own-styles(@apply-own-styles);
  @outer-styles();

  &::before,
  &::after {
    content: ' ';
    position: absolute;
    pointer-events: none;
    z-index: initial;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    @position();
  }
  &::before {
    .triangle(@border-color, @width, @height, @direction);
  }
  &::after {
    .triangle(
      @background-color,
      @width - @border-width*2,
      @height - @border-width,
      @direction
    );
    margin-right: @border-width;
  }
}

.fa-like(@unicode, @type: solid) {
  .apply-type(solid) {
    font-weight: 900;
  }
  .apply-type(regular) {
    font-weight: 400;
  }

  content: @unicode;
  font-family: 'Font Awesome 5 Free';
  .apply-type(@type);
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.right-edge-skew(
  @angle: -30deg,
  @height: 1em,
  @shadow: none,
  @backgroundColor: transparent,
  @borderRadius: 0px,
  @marginRight: 0,
  @paddingRight: 0
) {
  @slopeWidth: @height * tan(abs(@angle));
  @width: @height + @slopeWidth;
  @diff: floor(@height - @slopeWidth);

  position: relative;
  background-color: @backgroundColor;
  border-radius: @borderRadius;
  filter: drop-shadow(@shadow);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: @marginRight + @height;
  padding-right: @paddingRight - @height;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -@diff;
    width: @height;
    z-index: -2;
    transform-origin: bottom left;
    transform: skew(@angle, 0deg);
    background-color: @backgroundColor;
    border-radius: @borderRadius;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.square() {
  > * {
    vertical-align: top;
    display: inline-block;
  }

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
  }
}

// in some situations z-index does not work properly in safari
// e.g. in shipment detail when zooming in/out map, shipment milestones dissapears
// https://stackoverflow.com/a/40896165/741871
.safari-safe-z-index(@value) {
  -webkit-transform: translate3d(0, 0, 0);
  z-index: @value;
}
