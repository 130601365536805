.LinksRow {
  display: flex;
  align-items: center;
  font-size: @font-size-sm;

  > * {
    white-space: nowrap;
  }

  .VerticalDivider {
    @media (min-width: @screen-sm-min), (max-width: @screen-sm-max) {
      margin: 0 @spaceNorm;
    }
    @media (max-width: @screen-xs-max) {
      margin: 0 @spaceXs;
    }
  }

  &.big-spaces {
    > * {
      padding-left: @spaceNorm;
      padding-right: @spaceNorm;
    }
    > *:first-child {
      padding-left: 0;
    }
    > *:last-child {
      padding-right: 0;
    }
    > .VerticalDivider {
      padding: 0;
      margin: 0 @spaceNorm;
    }
  }
}

.DropdownMenu-Label,
.Popup-Label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  line-height: 1;

  @{fa-selector} {
    margin-left: @spaceXs;
  }
}

.dropdown-with-triangle(
  @dropdownPrefix: ~'ant-dropdown',
  @menuSelector: ~'.ant-dropdown-menu',
  @menuCustom,
  @menuItemCustom: {},
  @menuItemPrefix: ~'ant-dropdown-menu',
  @trianglePositionMultiplier: 1,
  @triangleCustom: {},
  @marginTop: 0,
  @menuItemActive: {.menu-item-active()},
  @menuItemHover: {.menu-item-hover()},
  @menuItemPressed: {.menu-item-pressed()},
  @boxShadow: 0px 2px 20px @colorGrey2,
  @borderColor: @colorWhite,
  @backgroundColor: @colorWhite4
) {
  @triangle-width: 18px;
  @triangle-height: 12px;
  @side-space: @spaceNorm;
  @vert-space: @spaceXs;

  .def-placement(@class, @triangleDirection, @trianglePosition) {
    @{class} {
      @{menuSelector} {
        .with-triangle(
          @width: @triangle-width,
          @height: @triangle-height,
          @border-color: @borderColor,
          @border-width: 1px,
          @background-color: @backgroundColor,
          @position: @trianglePosition,
          @direction: @triangleDirection
        );
      }
    }
  }
  .def-placement(
    @class: ~'&.@{dropdownPrefix}-placement-bottomRight',
    @triangleDirection: up,
    @trianglePosition: {
      bottom: 100%;
      right: @triangle-width * @trianglePositionMultiplier;
      @triangleCustom();
    }
  );
  .def-placement(
    @class: ~'&.@{dropdownPrefix}-placement-bottom, &.@{dropdownPrefix}-placement-bottomCenter',
    @triangleDirection: up,
    @trianglePosition: {
      bottom: 100%;
      right: 50%;
      transform: translateX(50%);
      @triangleCustom();
    }
  );
  .def-placement(
    @class: ~'&.@{dropdownPrefix}-placement-bottomLeft',
    @triangleDirection: up,
    @trianglePosition: {
      bottom: 100%;
      left: @triangle-width * @trianglePositionMultiplier;
      @triangleCustom();
    }
  );
  .def-placement(
    @class: ~'&.@{dropdownPrefix}-placement-topRight',
    @triangleDirection: down,
    @trianglePosition: {
      top: 100%;
      right: @triangle-width * @trianglePositionMultiplier;
      @triangleCustom();
    }
  );
  .def-placement(
    @class: ~'&.@{dropdownPrefix}-placement-top, &.@{dropdownPrefix}-placement-topCenter',
    @triangleDirection: down,
    @trianglePosition: {
      top: 100%;
      right: 50%;
      transform: translateX(50%);
      @triangleCustom();
    }
  );
  .def-placement(
    @class: ~'&.@{dropdownPrefix}-placement-topLeft',
    @triangleDirection: down,
    @trianglePosition: {
      top: 100%;
      left: @triangle-width * @trianglePositionMultiplier;
      @triangleCustom();
    }
  );

  @{menuSelector} {
    margin-top: @triangle-height - 2px + @marginTop;
    padding: 0;
    border-radius: @borderRadiusMd;
    box-shadow: @boxShadow;
    font-family: @font3;
    overflow: visible;
    @menuCustom();

    .@{menuItemPrefix}-item,
    .@{menuItemPrefix}-submenu-title {
      display: flex;
      align-items: stretch;
      height: 26px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      @menuItemCustom();

      &:first-child {
        &,
        & > a {
          .border-radius(@borderRadiusMd, top);
        }
      }
      &:last-child {
        &,
        & > a {
          .border-radius(@borderRadiusMd, bottom);
        }
      }
      &:first-child:last-child {
        &,
        & > a {
          border-radius: @borderRadiusMd;
        }
      }

      // Padding is in the child to prevent "unclickable" padding area,
      // becasue Menu.Item doesn't handle onClick prop
      > * {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 @side-space;
      }
      > * > * {
        display: flex;
        align-items: center;
        align-self: stretch;
        flex: 1;
        padding: 0 @side-space;
        margin: 0 -@side-space;
      }
    }

    .@{menuItemPrefix}-item {
      &,
      > a {
        transition: none;
      }
      &.active,
      &-selected,
      &-selected > a {
        position: relative;
        z-index: 1;
        @menuItemActive();
      }
      &:hover,
      > a:hover {
        position: relative;
        z-index: 1;
        @menuItemHover();
      }
      &:active,
      > a:active {
        position: relative;
        z-index: 1;
        @menuItemPressed();
      }
    }

    .@{menuItemPrefix}-item-divider   {
      .TwoLinesSeparator();
      overflow: visible;
      margin: 0 @side-space -1px @side-space;
      background-color: transparent;
      z-index: 0;
    }
  }
}

.DropdownMenu-Overlay {
  .dropdown-with-triangle(
    @dropdownPrefix: ~'ant-dropdown',
    @menuSelector: ~'.ant-dropdown-menu',
    @menuItemPrefix: ~'ant-dropdown-menu',
    @menuCustom: {width: 140px;},
    @trianglePositionMultiplier: 1
  );
  &.IconTrigger {
    .dropdown-with-triangle(
      @dropdownPrefix: ~'ant-dropdown',
      @menuSelector: ~'.ant-dropdown-menu',
      @menuItemPrefix: ~'ant-dropdown-menu',
      @menuCustom: {width: 140px;},
      @trianglePositionMultiplier: (1/2)
    );
  }
  &.DropdownTheme--Dark {
    .dropdown-with-triangle(
      @dropdownPrefix: ~'ant-dropdown',
      @menuSelector: ~'.ant-dropdown-menu',
      @menuItemPrefix: ~'ant-dropdown-menu',
      @menuCustom: {width: 140px;},
      @menuItemCustom: {color: @colorGreyBluish3;},
      @trianglePositionMultiplier: 1,
      @menuItemActive: {.dark-menu-item-active()},
      @menuItemHover: {.dark-menu-item-hover()},
      @menuItemPressed: {.dark-menu-item-pressed()},
      @boxShadow: 0px 1px 60px @colorBlack1,
      @borderColor: @colorGreyBluish2,
      @backgroundColor: @colorGreyDark5
    );
  }
  &.DropdownSize--Large {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item,
      .ant-dropdown-menu-submenu-title {
        height: 40px;
        font-size: @font-size-lg-minus;
        font-weight: normal;
        font-family: @fontStd;
      }
    }
  }
}
