.AdminUserList__Column {
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(1) {
    flex: 14.75;
    min-width: 0;
  }

  &:nth-child(2) {
    flex: 20.16;
    min-width: 0;
  }

  &:nth-child(3) {
    flex: 36.3;
    min-width: 0;
  }

  &:nth-child(4) {
    width: 60px;
    margin-right: 13%;
    text-align: center;
  }

  &:nth-child(5) {
    width: 45px;
    text-align: center;
  }
}
