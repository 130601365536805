.ant-drawer.ListEditDrawer {
  @header-height: 30px;
  @header-padding-hor: @spaceMd;
  @close-icon-size: @heading-4-size;

  &.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -4px 0 30px rgba(@colorGreyBluish, 0.9);
  }

  .ant-drawer-content-wrapper {
    height: auto;
    top: @spaceNorm;
    bottom: @spaceNorm;
    .border-radius(9px, left);

    @media (min-width: @screen-lg-min) {
      width: 964px !important;
      max-width: 96vw;
    }
    @media (max-width: @screen-md-max) {
      width: 96vw !important;
    }
  }

  .ant-drawer-content {
    .border-radius(9px, left);
    .mask(85);
    backdrop-filter: blur(3px);
  }

  .ant-drawer-mask {
    background-color: transparent;
  }
  .ant-drawer-header-title {
    height: @header-height;
    border-radius: 0;
    border-top-left-radius: 9px;
    background-color: @colorGreyBluish;
    padding: 0 @header-padding-hor;
    border-width: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .ant-drawer-close {
    position: relative;
    margin-right: 0;
    padding: 0;
  }
  .ant-drawer-title {
    color: @colorWhite;
    font-family: @fontCondensed;
    font-weight: bold;
    font-size: @font-size-lg;
  }
  .IconButton {
    color: @colorWhite;
    font-size: @close-icon-size;
  }
  .ant-drawer-body {
    padding: 0;
  }
}

.DrawerBase(@width, @mask: {background-color: transparent;}) {
  @borderRadius: {
    .border-radius(@borderRadiusLg, left);
  };

  .ant-drawer-close {
    position: absolute;
    margin-right: 0;
    padding: 0;
    top: @spaceSm;
    left: @spaceSm;
    right: initial;
  }

  .CloseButton {
    font-size: @heading-4-size;
    &,
    &:hover {
      color: @colorGrey3;
    }
  }

  > .ant-drawer-content-wrapper {
    @borderRadius();
    height: auto;

    @media (min-width: @screen-lg-min) {
      width: @width !important;
    }
    @media (max-width: @screen-md-max) {
      width: 320px !important;
    }

    > .ant-drawer-content {
      background-color: @colorWhite3;
      @borderRadius();
    }

    .ant-drawer-body {
      display: flex;
    }
  }
}

.ShipmentDetailDrawer {
  @paddingTop: @spaceNorm3;

  .DrawerBase(@width: 634px);

  &.wide {
    > .ant-drawer-content-wrapper {
      @media (min-width: @screen-lg-min) {
        width: 100% !important;
        padding-left: 390px;
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
        width: 100% !important;
        padding-left: 40px;
      }
      @media (max-width: @screen-xs-max) {
        width: 320px !important;
      }
    }
  }

  &.ant-drawer.ant-drawer-open .ant-drawer-mask {
    animation: none;
    background-color: transparent;
  }

  &.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -4px 4px 15px @colorGrey8_1;
  }

  .TopLeftHeader,
  .TopRightHeader {
    position: absolute;
    top: 0;
    height: @paddingTop;
    display: flex;
    align-items: center;
  }

  .TopLeftHeader {
    left: @spaceXl;

    .red-link {
      font-size: @font-size-sm-plus;
      font-weight: 500;
      color: @colorRed;
      text-decoration: underline;
      line-height: 1;
    }
  }

  .TopRightHeader {
    right: @spaceNorm3;
  }

  > .ant-drawer-content-wrapper {
    top: 60px;
    bottom: 55px;

    > .ant-drawer-content {
      border: 2px solid @colorWhite;
    }

    .ant-drawer-body {
      padding: @paddingTop @spaceNorm2 @spaceNorm2 @spaceNorm2;
      display: flex;

      > iframe {
        width: 100%;
        align-self: stretch;
        border: 0;
      }
    }
  }
}

.ShipmentAssetsInventoryDrawer,
.ShipmentPackagePieceListDrawer {
  &.ant-drawer.ant-drawer-open .ant-drawer-mask {
    .mask(65);
    z-index: initial;
    backdrop-filter: blur(2px);
  }

  > .ant-drawer-content-wrapper {
    @media (min-width: @screen-lg-min) {
      width: 679px !important;
    }

    > .ant-drawer-content {
      border-width: 0;
      overflow: visible;
    }

    .ant-drawer-close {
      left: @spaceMd;
    }

    .ant-drawer-header-title {
      position: relative;
      height: 42px;
      background-color: @colorWhite;
      border-bottom-width: 0;
      padding: 0;
      border-top-left-radius: @borderRadiusLg;

      .ant-drawer-title {
        background-color: @colorWhite7;
        border: 1px solid @colorWhite;
        height: 32px;
        line-height: 32px;
        font-family: @fontCondensed;
        font-weight: bold;
        font-size: @heading-4-size;
        text-align: center;
        box-shadow: 0 4px 10px rgba(@colorGreyBluish7_2, 0.25);
        border-top-left-radius: @borderRadiusLg;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        border-top: 1px solid @colorWhite;
        border-bottom: 1px solid @colorGrey8_1;
      }
    }

    .ant-drawer-body {
      border: 2px solid @colorWhite;
      border-top-width: 0;
      border-bottom-left-radius: @borderRadiusLg;
      overflow: hidden;
      .responsive-md-vs-lg(
        @md-and-smaller: {
          padding: @spaceNorm 0;
        },
        @lg-and-bigger: {
          padding: @spaceNorm @spaceNorm2;
        }
      );
    }
  }
}

.NewShipmentDrawer {
  .DrawerBase(@width: 685px);

  &.ant-drawer.ant-drawer-open .ant-drawer-mask {
    .mask(65);
    backdrop-filter: blur(2px);
    z-index: auto;
  }

  .ant-drawer-header {
    padding: 0;
  }

  .ant-drawer-header-title {
    .flex-center();
    position: relative;
    height: 40px;
    border: 1px solid @colorWhite;
    border-top-left-radius: @borderRadiusLg;
    background-color: @colorWhite3;
    box-shadow: 0 3px 10px rgba(@colorGreyBluish3, 0.5);
    z-index: 3;

    &::before {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: @colorWhite;
    }

    .ant-drawer-title {
      flex: 0 1 auto;
      font-family: @fontCondensed;
      font-size: @heading-4-size;
      font-weight: bold;
    }
  }

  &.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -2px 0 35px rgba(@colorGrey6, 0.5);
  }

  > .ant-drawer-content-wrapper {
    top: 20px;
    bottom: 15px;
    z-index: 100;

    > .ant-drawer-content {
      border: 1px solid @colorWhite;
    }

    .ant-drawer-body {
      position: relative;
      padding: 0;
      overflow: hidden;

      @media (min-width: @screen-lg-min) {
        padding: @spaceNorm2 @spaceNorm @spaceNorm1_5 0;
      }
      @media (max-width: @screen-md-max) {
        padding: @spaceNorm 0;
      }
    }
  }

  .NewShipmentDrawer-HeaderRightContent {
    position: absolute;
    right: @spaceSm;
    top: 50%;
    transform: translateY(-50%);

    .ant-btn {
      @{fa-selector} {
        &:first-child {
          margin-right: @spaceXs;
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }

    .responsive-md-vs-lg(
      @md-and-smaller: {
        display: none;
      },
      @lg-and-bigger: {
        display: block;
      }
    );
  }
}

.ShipmentEmailNotificationsDrawer {
  .DrawerBase(@width: 320px);

  &.ant-drawer.ant-drawer-open .ant-drawer-mask {
    .mask(65);
    backdrop-filter: blur(2px);
  }

  .ant-drawer-content-wrapper {
    top: @spaceSm;
    bottom: @spaceNorm3;
    box-shadow: -4px 4px 35px rgba(@colorGrey6, 0.65);
  }

  .ant-drawer-content {
    border: 2px solid @colorWhite;
  }

  .ant-drawer-header-title {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-bottom-width: 0;

    .ant-drawer-title {
      font-family: @fontCondensed;
      font-weight: bold;
      font-size: @font-size-base;
      text-transform: uppercase;
    }
  }

  .ant-drawer-body {
    padding: 0 @spaceNorm @spaceNorm2 @spaceNorm;
    flex-direction: column;
    align-items: stretch;
  }

  h4.ant-typography {
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.24px;
    color: #6d7889;
    padding-top: @spaceXs;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
    margin-bottom: @spaceNorm;
  }

  .EmailNotifications__ActiveNotifications__Title {
    display: none;
  }

  .EmailNotifications__Card__Body .ant-btn-lg {
    font-size: @font-size-base;
    letter-spacing: -0.1px;
  }
}
