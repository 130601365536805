#TermsOfService__Page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .responsive-md-vs-lg(
    @md-and-smaller: {
      padding: @spaceNorm2 @spaceNorm;
    },
    @lg-and-bigger: {
      padding: @spaceXl @spaceNorm2;
    }
  );

  &::before {
    content: ' ';
    .absolute(0);
    opacity: 0.65;
    background-image: url('../../../resources/img/abstract-background.jpg');
    background-size: cover;
    background-position: top center;
    background-color: @colorWhite3;
  }
}

.TermsOfService__Container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 800px;
  border-radius: @borderRadiusMd;
  background-color: @colorWhite2;
  box-shadow: 2px 2px 25px rgba(@colorGreyBluish7, 0.35);

  .responsive-md-vs-lg(
    @md-and-smaller: {
      padding: @spaceNorm @spaceNorm;
    },
    @lg-and-bigger: {
      padding: @spaceNorm2 @spaceNorm3;
    }
  );

  .QuickOnlineLogo {
    margin-bottom: @spaceNorm2;
  }

  .ThreeLines,
  .Scrollbar {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        margin-left: -@spaceNorm;
        margin-right: -@spaceNorm;
      },
      @lg-and-bigger: {
        margin-left: -@spaceNorm3;
        margin-right: -@spaceNorm3;
      }
    );
  }

  .ThreeLines {
    margin-top: @spaceNorm;
    margin-bottom: @spaceNorm1_5;
  }
}

.TermsOfService__Content {
  font-weight: 500;

  p {
    margin-bottom: @spaceMd;
  }

  h1 {
    font-size: @heading-1-size;
  }
  h2 {
    font-size: @heading-2-size;
  }
  h3 {
    font-size: @heading-3-size;
  }

  h1,
  h2,
  h3 {
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: @spaceNorm;
  }

  ul,
  ol {
    margin-bottom: @spaceNorm1_5;
    padding-left: 16px + @spaceNorm;
  }

  li {
    padding-left: @spaceNorm2 - 12px;
  }

  .responsive-md-vs-lg(
    @md-and-smaller: {
      padding: 0 @spaceNorm;
    },
    @lg-and-bigger: {
      padding: 0 @spaceNorm3;
    }
  );
}

.TermsOfService__Checkbox {
  .ant-checkbox-inner {
    border-color: @colorRed;
  }
  label {
    font-size: @font-size-lg;
    color: @colorRed;
  }
  .ant-checkbox + span {
    padding-left: @spaceSm;
  }
}

.TermsOfService__Buttons {
  display: flex;
  align-items: center;

  button {
    width: 110px;
    .responsive-md-vs-lg(
      @md-and-smaller: {
        .space-between(@spaceNorm, horizontal);
      },
      @lg-and-bigger: {
        .space-between(@spaceNorm2, horizontal);
      }
    );
  }
}
