.ReleaseShipmentPage {
  .ReleaseShipmentPage__TopRow {
    row-gap: @spaceNorm2 !important;

    h2.ant-typography {
      font-family: @fontCondensed;

      @media (min-width: @screen-lg-min) and (max-width: @screen-md-max) {
        font-size: @heading-3_5-size;
      }
      @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        font-size: @heading-4-size;
      }
      @media (max-width: @screen-sm-max) {
        font-size: @font-size-lg;
      }

      margin-bottom: @spaceNorm;
      line-height: 1;
    }
    .LabelWithValue .label.size-full {
      font-size: @font-size-xs;
      font-weight: 500;
    }
  }

  .ReleaseShipmentPage__TopButtons {
    display: flex;

    > * {
      .space-between(@spaceNorm, horizontal);
    }

    > .ant-btn {
      width: 55px;
      font-size: @font-size-sm-plus;
    }
    > .ant-btn.ant-btn-primary {
      @media (min-width: @screen-lg-min) {
        width: 120px;
      }
      @media (max-width: @screen-sm-max) {
        width: 100px;
      }
      font-size: @font-size-base;
    }
  }

  .ReleaseShipmentPage__Card {
    border: 2px solid @colorWhite;
    background-color: @colorWhite2;
    border-radius: @borderRadiusSm;
    padding-bottom: @spaceNorm1_5;
    box-shadow: 4px 4px 20px @colorGrey8_1;

    > .ant-row {
      row-gap: @spaceNorm2 !important;
    }

    .responsive-md-vs-lg(
      @md-and-smaller: {
        padding: @spaceNorm;
      },
      @lg-and-bigger: {
        padding: @spaceMd @spaceNorm2 @spaceNorm2 @spaceNorm2;
      }
    );

    .JobLocationCard-Section .JobLocationCard-SectionTitle {
      color: @colorGrey3;
    }

    .DividerCol {
      padding-bottom: @spaceNorm;
    }
  }
}
