.VerticalDivider {
  margin: 0 @spaceMd;
}

.ColVerticalDivider {
  margin: 0;
  border-right: 1px solid @colorGrey6;
  height: 40px;

  &.sm {
    height: 30px;
  }

  &.full {
    height: 100%;
  }
}

.HorizontalDividerWithCircle {
  position: relative;
  display: flex;
  justify-content: center;

  &-Line {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 0;
    border-top: 1px solid @colorGrey9;
  }

  &-Circle {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: @colorWhite;
    border: 1px solid @colorGrey9;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @colorGrey3;
    font-size: 14px;
    font-family: @font2;
  }
}

.FlexCol {
  display: flex;
  flex-direction: column;

  &.FlexCol-NoOverflow {
    &,
    & > * {
      overflow: hidden;
    }
  }
}

.Flex1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.FlexAlignStretch {
  align-self: stretch;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-height {
  height: 100%;
}

.TitleWithExtra {
  margin-bottom: @spaceNorm;
  color: @colorGreyBluish2;
  h3.ant-typography {
    font-family: @fontCondensed;
    font-weight: bold;
    letter-spacing: -0.33px;
    color: @colorGrey3;
    margin-bottom: 0;

    @media (min-width: @screen-lg-min) {
      font-size: 26px;
      line-height: 40px;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      font-size: 22px;
      line-height: 30px;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      font-size: 20px;
      line-height: 20px;
    }
    @media (max-width: @screen-xs-max) {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .ant-btn {
    .space-between(@spaceSm);
  }
  .ant-col:last-child {
    .flex-center();
  }
}

.TwoLines {
  height: 10px;
  border-top: 1px solid @colorWhite;
  border-bottom: 1px solid @colorGrey8_1;

  @sizes: {
    6: 6px;
    10: 10px;
    20: 20px;
    30: 30px;
    40: 40px;
  };
  each(@sizes, {
    &.size-@{key} {
      height: @value;
    }
  });
}
.ThreeLines {
  position: relative;
  height: 20px;
  border-top: 1px solid @colorWhite;
  border-bottom: 1px solid @colorWhite;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 1px solid @colorGrey8_1;
  }
}
