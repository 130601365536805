.SlidingContent {
  width: 100%;

  &.vertical {
    display: flex;
    flex-direction: column;

    .SlidingContent-ContentAnimationContainer {
      flex: 1;
    }
  }

  &-Buttons {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    &-Button {
      flex: 1;
      .space-between(@spaceXs);
    }
  }

  &-Content {
    // This is a reserve for shadows and other graphical content on the edge
    margin: 0 -20px;
    overflow: hidden;
    position: relative;
  }

  &-ContentOverlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  &-ContentOverlay > * {
    pointer-events: initial;
  }

  &.anim-md,
  & {
    .SlidingContent-ContentAnimationContainer {
      transition: transform @animationDurationMd;
    }
  }
  &.anim-lg {
    .SlidingContent-ContentAnimationContainer {
      transition: transform @animationDurationLg;
    }
  }

  &-ContentAnimationContainer {
    width: 100%;
    height: 100%;
    white-space: nowrap;

    &.vertical {
      white-space: normal;
    }
  }

  &-ContentTab {
    width: 100%;
    min-height: 100%;
    display: inline-block;
    white-space: initial;
    vertical-align: top;
    padding: 0 20px;
    overflow: hidden;

    .SlidingContent-Content-AutoHeight & {
      min-height: 0;
    }
  }
}
