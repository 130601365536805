.AdminUserForms__UserSpecificSettingsColumns {
  > :nth-child(1) {
    flex: 2;
  }
  > :nth-child(2) {
    flex: 2;
  }
  > :nth-child(3) {
    flex: 2;
  }
  > :nth-child(4) {
    flex: 3;
  }
}

.AdminUserForms__LinkedAccountsColumns {
  &,
  .AdminUserForms__LinksEditing__DataHeader&,
  .AdminUserForms__LinksEditing__DataBody .AdminUserForms__Checkboxes__Item& {
    > :nth-child(1) {
      flex: 3;
    }

    > :nth-child(2) {
      flex: 5;
    }

    > :nth-child(3) {
      flex: 3;
    }

    > :nth-child(4) {
      flex: 3;
    }

    > :nth-child(5) {
      height: 30px;
      width: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.AdminUserForms__LinkedGroupsColumns {
  > :nth-child(1) {
    flex: 1;
  }
  > :nth-child(2) {
    flex: 1;
  }
  > :nth-child(3) {
    height: 30px;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.AdminUserForms__LinkedAccountsLabel {
  height: 30px;
  line-height: 30px;
  border-radius: @borderRadiusSm;
  background-color: @colorGreyDark2;
  color: @colorOrange1;
  padding: 0 @spaceNorm;
  font-size: @font-size-lg-minus;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AdminUserForms__LinkedAccountsLabel--Tba {
  background-color: @colorGreyDark2_6;
}

.AdminUserForms__LinkedAccountsLabel--Emph {
  text-decoration: underline;
}

.AdminUserForms__CollapsibleHeader {
  height: 30px;
  display: flex;
  align-items: center;
  gap: @spaceSm;
  padding: 0 @spaceNorm2;
  font-family: @fontCondensed;
  font-size: @font-size-sm-plus;
  color: @colorGreyBluish7_4;
  border-bottom: 1px solid @colorGreyDark1_1;
  border-top: 1px solid @colorGrey3_5;
  line-height: 1;

  &:first-child {
    border-top-width: 0;
  }
}

.AdminUserForms__CollapsibleHeader__Description {
  color: @colorGrey7_1;
}

.AdminUserForms__LinksEditing {
  position: relative;
  padding-bottom: @spaceNorm1_5;
}

.AdminUserForms__LinksEditing__DeleteButton {
  color: @colorGreyBluish7_4;
}

.AdminUserForms__LinksEditing__AddButton {
  position: absolute;
  right: @spaceNorm2;
  top: 100%;
  margin-top: @spaceNorm0_5 - @spaceNorm1_5;
  font-family: @fontCondensed;
  font-size: @font-size-sm-minus;
  color: @colorGreyBluish7_4;
  text-transform: uppercase;

  @{fa-selector} {
    margin-left: @spaceSm;
  }
}

.AdminUserForms__LinksEditing__SearchContainer {
  padding: @spaceMd @spaceNorm2;
  border-bottom: 1px solid @colorGreyDark1_1;
  .FormTheme--DarkSimple();
}

.AdminUserForms__LinksEditing__DataHeader,
.AdminUserForms__LinksEditing__DataBody .AdminUserForms__Checkboxes__Item {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 @spaceNorm2;

  > * {
    flex: 1;
  }
}
.AdminUserForms__LinksEditing__DataBody
  .AdminUserForms__Checkboxes__Item
  .ant-checkbox
  + span {
  padding-left: @spaceNorm;
}

.AdminUserForms__LinksEditing__DataBody--Empty {
  height: 40px;
  padding: 0 @spaceNorm2;
  display: flex;
  align-items: center;
  color: @colorOrange1;
  font-size: @font-size-lg;
  font-weight: 500;
  text-transform: uppercase;
}

.AdminUserForms__LinksEditing__DataHeader {
  background-color: @colorGreyDark3;
  border-top: 1px solid @colorGrey3_5;
  font-family: @fontCondensed;
  font-weight: 700;
  color: @colorGreyBluish8;

  @{fa-selector} {
    margin-left: @spaceSm;
  }
}

.AdminUserForms__Checkboxes__Item {
  color: @colorGreyBluish3_2;
  background-color: @colorGreyDark2_7;
  line-height: 1;

  .ant-checkbox + span {
    font-weight: normal;
    color: @colorGreyBluish3_2;
    font-size: @font-size-base;
  }

  &:nth-child(odd) {
    border-bottom: 1px solid @colorGreyDark1_3;
  }
  &:nth-child(even) {
    border-bottom: 1px solid @colorGrey3_5;
  }
  &:last-child {
    border-bottom-width: 0;
  }

  &:hover {
    &,
    .ant-checkbox + span {
      color: @colorOrange3;
    }
  }
}

.AdminUserForms__Checkboxes__Item--Active {
  &,
  .ant-checkbox + span {
    color: @colorOrange1;
  }
}

.AdminUserForms__ExpandedTracking__ListControls {
  padding: 0 !important;
  height: @input-height-md;
  display: flex;
  align-items: center;
  gap: @spaceSm;
  color: @colorGreyBluish7_4;
}

.AdminUserForms__ExpandedTracking__MaxItemsLabel {
  height: 0;
  padding-top: @spaceXs;
  text-align: right;
  font-size: @font-size-xs;
  color: @colorGreyBluish8;
  line-height: 1;
  margin-right: -@spaceNorm;
}
