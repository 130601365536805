@sel-outer: .TopMenu-MenuItem;
@sel-inner: .TopMenu-MenuItemInner;

.TopMenu {
  display: flex;

  @{sel-outer} {
    cursor: pointer;
    .flex-center();

    .def-clr(@color) {
      color: @color;
      &::before {
        background-color: @color;
      }
    }

    > @{sel-inner} {
      color: @colorGrey3;
    }
    &.active > @{sel-inner} {
      .def-clr(@colorRed);
    }
    &:hover > @{sel-inner} {
      .def-clr(@colorRed6);
    }
    &:active > @{sel-inner},
    &.ant-dropdown-open:not(:hover) > @{sel-inner} {
      .def-clr(@colorRed7);
    }

    &.has-arrow {
      > @{sel-inner}::after {
        font-size: 21px;
        .fa-like('\f0d7');
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  @{sel-inner} {
    position: relative;
    width: 100%;
    .flex-center();
    transition: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
    }
  }
}

// Responsive
.TopMenu {
  height: 60px;
  margin-right: 25px;
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    margin-right: 22px;
  }
  @media (max-width: @screen-sm-max) {
    height: 50px;
    margin-right: 7px;
  }

  @{sel-outer} {
    padding: 0 25px;
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      padding: 0 22px;
    }
    @media (max-width: @screen-sm-max) {
      padding: 0 7px;
    }
  }
  @{sel-inner} {
    height: 36px;
    font-size: @font-size-lg-plus;
    font-weight: bold;
    @media (max-width: @screen-md-max) {
      font-weight: 500;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      font-size: @font-size-lg;
    }
    @media (max-width: @screen-xs-max) {
      font-size: @font-size-lg-minus;
    }
  }
  @{sel-outer}.has-arrow > @{sel-inner}::after {
    @media (min-width: @screen-md-min) {
      margin-left: @spaceSm;
    }
    @media (max-width: @screen-sm-max) {
      margin-left: @spaceXs;
    }
  }
}

.TopMenu-Submenu {
  .submenu-dropdown-with-triangle(@arrow-margin-right, @margin-right) {
    .dropdown-with-triangle(
      @menuCustom: {width: 175px; margin-right: @margin-right;},
      @triangleCustom: {right: 18px + @arrow-margin-right;},
      @marginTop: -13px
    );
  }

  @media (min-width: @screen-lg-min) {
    .submenu-dropdown-with-triangle(@spaceMd, 0);
  }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .submenu-dropdown-with-triangle(@spaceMd, -11px);
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .submenu-dropdown-with-triangle(@spaceXs, -21px);
  }
  @media (max-width: @screen-xs-max) {
    .submenu-dropdown-with-triangle(@spaceXxs, -19px);
  }

  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: @colorGrey3;
  }
}
