.AdminAccountList__Column {
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(1) {
    flex: 4;
    min-width: 0;
  }

  &:nth-child(2) {
    flex: 5;
    min-width: 0;
  }

  &:nth-child(3) {
    flex: 4;
    min-width: 0;
  }

  &:nth-child(4) {
    flex: 4;
    min-width: 0;
  }

  &:nth-child(5) {
    width: 45px;
    text-align: center;
  }
}
