.AdminAccountDetail__HeaderRight {
  display: flex;
  align-items: center;

  line-height: 1;
  font-family: @fontCondensed;
  font-size: @font-size-lg;
  font-weight: bold;
  color: @colorGreyBluish7_4;
  .AdminConsoleContent__Header--Editing & {
    color: @colorBrown1;
  }

  .ant-btn.AdminConsole__ButtonDropdownTrigger {
    font-family: @fontStd !important;
    font-size: @font-size-lg !important;
    font-weight: bold !important;
    color: @colorGreyBluish7_4 !important;
    opacity: 1 !important;
    cursor: text;

    .AdminConsoleContent__Header--Editing & {
      color: @colorWhite !important;
    }
  }
}

.AdminAccountDetail__ThinCheckboxes {
  .ant-checkbox-wrapper {
    &,
    &.ant-checkbox-wrapper-checked {
      color: @colorGreyBluish7_4;
      font-family: @fontCondensed;
      font-size: @font-size-sm-plus;
    }
    .ant-checkbox-inner {
      width: 22px;
      height: 22px;
      font-size: 18px;
    }
  }
  .ant-checkbox-wrapper .ant-checkbox-disabled {
    & + span {
      opacity: 1;
      padding-left: @spaceNorm;
    }
  }
  .ant-checkbox-wrapper {
    &.vertical-reverse,
    &.vertical {
      .ant-checkbox + span {
        padding-left: 0;
      }
    }
  }
  .ant-form-item-label > label {
    color: @colorGreyBluish7_4;
    font-family: @fontCondensed;
    font-size: @font-size-sm-plus;
    height: auto;
  }
}

.ant-modal.SimpleDialog.AdminAccountDetail__BusinessTypeDialog {
  .ant-modal-content {
    height: 300px;

    @media (min-width: @screen-lg-min) {
      width: 480px;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: 360px;
    }
    @media (max-width: @screen-sm-max) {
      width: 280px;
    }
  }
  .ant-modal-body {
    align-items: stretch;
  }

  // Theme overrides
  .ButtonTheme--DarkSimple();
  .AntGeneralTheme--DarkSimple();

  .ant-btn-lg {
    @media (min-width: @screen-lg-min) {
      font-size: @heading-4-size;
    }
  }
}
.AdminAccountDetail__BusinessTypeDialog__Subtitle {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 @spaceMd;
  background-color: @colorGreyDark2;
  border: 1px solid @colorGreyDark5;
  box-shadow: 1px 1px 40px rgba(@colorBlack0_7, 0.65);
  font-family: 'League Gothic';
  font-size: @heading-2-size;
  color: @colorGrey7_4;
  border-radius: @borderRadiusSm;
}

.AdminAccountTransferPage {
  max-height: 800px;
  display: flex;

  .ant-row {
    flex-grow: 1;
    height: 100%;
  }

  .ant-form-item-control {
    height: 100%;
  }

  .ant-form-item-control-input {
    height: 100%;
    align-items: stretch;
  }
}
