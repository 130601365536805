.AccountSelect__Dropdown {
  background-color: @colorWhite6;
  border-radius: @borderRadiusSm;
  border: 1px solid @colorWhite;
  box-shadow: 0 2px 20px @colorGreyBluish3;

  .ant-select-item {
    min-height: 0;
  }

  .ant-select-item-group,
  .SearchSingleSelect--Group {
    position: relative;
    padding: 0 0 0 @spaceNorm1_5;
    line-height: 16px;
    margin-top: @spaceSm;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -@spaceXs;
      left: 0;
      right: 0;
      transform: translateY(-100%);
      height: @spaceXs;
      border-top: 1px solid @colorWhite;
      border-bottom: 1px solid @colorGrey8_1;
    }
    &:first-child {
      margin-top: 0;
      &::before {
        display: none;
      }
    }
  }

  .ant-select-item-option,
  .SearchSingleSelect--Item {
    line-height: 18px;
    transition: none;

    &:hover {
      background-color: @colorRed;
      color: @colorWhite;
    }
  }
}

.AccountSelect__Group--Partner {
  color: @colorBlue3;
  font-style: italic;
  font-weight: 900;
  font-size: @font-size-sm;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

.AccountSelect__Group--NonPartner {
  color: @colorRed;
  font-weight: 900;
  font-size: @font-size-sm;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
