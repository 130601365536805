.CheckableGroup--Vertical {
  display: block;

  .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
  }
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .space-between(@spaceNorm, vertical);
    margin-right: 0;
    line-height: 1;
  }

  .ant-radio,
  .ant-checkbox {
    margin-right: @spaceNorm;
  }
}

.label-style() {
  font-size: @font-size-base;
  color: @default-placeholder-color;
  line-height: 1;
}

.ant-picker-input > input {
  &:placeholder-shown {
    .label-style();
  }
}

.FloatingLabelWrapper {
  @small-label-font-size: 11px;
  @value-from-top-base: @small-label-font-size + @spaceXs + 2px;

  position: relative;

  > label {
    pointer-events: none;
    position: absolute;
    left: @spaceMd;
    top: 50%;
    .label-style();
    transform: translateY(-50%);
    transition: all 0.25s ease-out;
  }
  > .has-prefix + label {
    left: @spaceNorm5 + @spaceNorm0_5;
  }

  &.is-required > label::after {
    .text-after('*');
    .ant-form-hide-required-mark & {
      content: none;
    }
  }
  &.is-required > .ant-input-view-mode + label::after {
    content: none;
  }

  &.is-disabled > label {
    opacity: 0.5;
  }

  &.is-focused > label,
  &.has-value > label,
  > .ant-select-focused + label,
  > .ant-picker-focused + label {
    font-size: @small-label-font-size;
    top: @spaceXs + 2px;
    transform: none;
  }

  &.is-focused,
  &.has-value,
  > .ant-select-focused,
  > .ant-picker-focused {
    .ant-picker > .ant-picker-input > .ant-picker-suffix {
      display: block;
    }
  }

  > input,
  > .ant-input-affix-wrapper > input.ant-input {
    line-height: 1;
    padding-top: @value-from-top-base;
    padding-bottom: @spaceXs;
    padding-left: @spaceMd;
    padding-right: @spaceMd;
  }
  > .ant-input-affix-wrapper.has-prefix > input.ant-input {
    padding-left: 0;
  }

  > .ant-input-number > * > input {
    line-height: 1;
    padding-top: @value-from-top-base;
    padding-bottom: @spaceXs;
    padding-left: @spaceMd - 1px;
    padding-right: @spaceMd;
  }

  .ant-select .ant-select-selector {
    padding-top: @value-from-top-base;
    padding-bottom: @spaceXs;
    padding-left: @spaceMd;
    padding-right: @spaceMd;

    .ant-select-selection-item {
      display: initial;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      padding-bottom: @spaceXs;
      margin-bottom: -@spaceXs;
      margin-top: 1px;
    }

    &,
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 1;

      &::after {
        line-height: 1;
      }
    }
  }

  .ant-picker {
    &.suffix-as-prefix .ant-picker-input {
      .ant-picker-suffix {
        margin-left: 0;
        margin-right: @spaceNorm0_5;
      }
    }
    .ant-picker-input {
      padding-top: @value-from-top-base + 2px;
      padding-bottom: @spaceXs;
      padding-left: @spaceMd;
      padding-right: @spaceMd;

      > input {
        padding: 0;
      }

      .ant-picker-suffix {
        display: none;
        @{fa-selector} {
          font-size: 16px;
        }
      }
    }
  }
}

.InputSearchFlat {
  @height: 30px;
  .ant-form-item-control-input-content &.ant-input-affix-wrapper {
    display: flex;
    align-items: center;
    height: @height;
    border: 1px solid @colorGrey8_7;
    background-color: @colorWhite2;
    border-radius: (@height / 2);
    padding: 0 @spaceSm 0 @spaceNorm1_5;
    box-shadow: none;

    &::after {
      content: none;
    }

    .ant-input-prefix {
      margin-right: @spaceMd;
    }

    .ant-input-suffix {
      margin-left: @spaceMd;
      @{fa-selector} {
        font-size: 13px;
      }
    }

    .ant-input {
      background-color: transparent;
      color: @colorGrey3;

      &::placeholder {
        color: @colorGreyBluish;
        font-weight: normal;
      }
    }
  }
}

.LetterInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: @spaceNorm;
}

.LetterInput__Letter {
  text-align: center;

  .LetterInput--size-sm & {
    width: @input-height-sm;
    height: @input-height-sm;
  }
  .LetterInput--size-md & {
    width: @input-height-md;
    height: @input-height-md;
  }
  .LetterInput--size-lg & {
    width: @input-height-lg;
    height: @input-height-lg;
  }
}

.PinField__ShowToggle {
  width: 50px;
  font-size: @font-size-sm-plus;
  color: @colorRed;
}

.DynamicFormFieldLabelRow {
  .no-margin-form-items();

  > .ant-col:first-child {
    display: flex;
    align-items: center;
    height: @input-height-md;
  }

  > .ant-col:last-child {
    .ant-radio-group {
      margin-top: 9px;
    }
  }
}
