.ShipmentOrderPreviewCard {
  width: 252px;
  min-width: 252px;
  @left-size: 140px;

  &-Section {
    margin: 0 @spaceNorm;
    padding: @spaceNorm 0 @spaceSm 0;
    font-size: @font-size-sm-plus;
    line-height: 16px;

    & + .TwoLinesSeparator {
      margin: 0 @spaceNorm3 0 @spaceNorm;
    }

    > .ant-row {
      margin-right: -@spaceNorm;

      > .ant-col:first-child {
        width: @left-size;
      }
    }

    &:last-child {
      padding-bottom: @spaceNorm1_5;
    }

    h4.ant-typography {
      line-height: 1;
      font-family: @fontCondensed;
      font-weight: bold;
      color: @colorGreyBluish;
    }

    .LabelWithValue {
      margin-bottom: @spaceXxs;

      .label.size-md {
        width: @left-size;
      }
    }
  }

  .ShipmentOrderPreview__Warning {
    font-size: @font-size-sm-plus;
    font-weight: normal;
    font-style: italic;
    color: @colorBlue2;
    margin-top: @spaceSm;
    text-align: center;
  }

  .ShipmentOrderPreview__DateLabelWithValue.LabelWithValue .label {
    font-weight: bold;
    font-size: @font-size-xs;
    color: @colorBlue3;
  }
}
