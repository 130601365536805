.NonLoggedPage {
  &-InnerContent {
    align-items: initial;

    > * > .Scrollbar-View {
      display: flex;
      flex-direction: column;
    }
  }

  &-ScrollContent {
    margin: auto 0;
    display: flex;
    flex-direction: column;
  }

  &-Columns {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: @screen-sm-max) {
      flex: 1;
    }

    > * {
      @media (min-width: @screen-xl-min) {
        .space-between(@spaceXl);
      }
      @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
        .space-between(@spaceNorm3);
      }
      @media (max-width: @screen-md-max) {
        .space-between(@spaceNorm2);
      }
    }

    .column-wide {
      @media (min-width: @screen-xl-min) {
        width: 480px;
      }
      @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
        width: 380px;
      }
      @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .Flex1();
      }
      @media (max-width: @screen-sm-max) {
        display: none;
      }
    }
  }
}

#LoginPage {
  .NonLoggedPage-Columns > * {
    @media (min-width: @screen-xl-min) {
      height: 485px;
    }
    @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
      height: 420px;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      height: 390px;
    }
  }
}
.PasswordResetPage,
.SignupPage {
  .NonLoggedPage-ScrollContent {
    align-items: center;
  }
}

.AuthEnvelope {
  @logo-selector: ~'.QuickOnlineLogoQ, .QuickOnlineLogo';

  background-color: @colorWhite2;
  box-shadow: 2px 2px 25px rgba(@colorGreyBluish, 0.35);
  border: 1px solid @colorWhite;
  border-radius: @borderRadiusMd;

  @media (min-width: @screen-xl-min) {
    width: 460px;
    padding: @spaceNorm3 @spaceXl;
  }
  @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
    width: 420px;
    padding: @spaceNorm2 @spaceNorm3;
  }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    width: 320px;
    padding: @spaceNorm2;
  }
  @media (max-width: @screen-sm-max) {
    width: 100%;
    align-self: stretch;
    padding: @spaceNorm2 0;
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
  }

  @{logo-selector} {
    margin: 0 auto;
  }

  &-Title {
    text-align: center;
    color: @colorGrey3;
    font-size: @heading-1-size;
    font-weight: 300;
    line-height: 42px;
    margin-bottom: @spaceNorm2;
  }

  &-Subtitle {
    text-align: center;
    color: @colorGrey3;
    font-size: @heading-3_5-size;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: @spaceNorm;
  }

  &-InfoText {
    text-align: center;
    color: @colorGrey3;
    font-size: @heading-4-size;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: @spaceXl;
  }

  .ant-btn {
    width: 100%;
    height: @input-height-lg;
    font-size: @heading-3-size;
    letter-spacing: 0.5px;
    font-weight: normal;
    box-shadow: 0 4px 20px rgba(@colorGreyBluish, 0.85);
  }

  &-Links {
    color: @colorRed;
    font-weight: bold;
    font-size: @font-size-lg;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-checkbox-wrapper {
    color: @colorGrey3;
    font-size: @font-size-lg;
  }

  &.LoginForm {
    padding-top: @spaceNorm2;

    @{logo-selector} {
      @media (min-width: @screen-xl-min) {
        margin-bottom: @spaceXl;
      }
      @media (max-width: @screen-lg-max) {
        margin-bottom: @spaceNorm2_5;
      }
    }
    &-Title {
      margin-bottom: @spaceNorm;
    }
    .LoginForm__FormItems {
      > :nth-child(1) {
        margin-bottom: @spaceNorm2_5;
      }
      > :nth-child(2) {
        margin-bottom: @spaceNorm;
      }
      > :nth-child(3) {
        @media (min-width: @screen-xl-min) {
          margin-bottom: @spaceNorm3;
        }
        @media (max-width: @screen-lg-max) {
          margin-bottom: @spaceNorm2;
        }
      }
      > :nth-child(4) {
        margin-bottom: @spaceNorm1_5;
      }
    }
  }

  .CenterOfScreenForm(@max-height) {
    @media (min-width: @screen-xl-min) {
      min-height: @max-height;
    }
    @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
      min-height: min(420px, @max-height);
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      min-height: min(390px, @max-height);
    }

    > .QuickOnlineLogo {
      margin-bottom: @spaceSm;
    }

    h4.ant-typography {
      color: @colorGrey2;
      text-align: center;
      font-weight: 400;
      line-height: 21px;
    }

    a {
      text-decoration: underline;
    }
  }

  &.PasswordResetInitForm {
    .CenterOfScreenForm(430px);

    .AuthEnvelope-Title {
      margin-bottom: @spaceNorm2;
    }
    form > * {
      margin-bottom: @spaceNorm2;
    }
  }
  &.PasswordResetEmailSentMessage {
    .CenterOfScreenForm(260px);

    .AuthEnvelope-Title {
      margin-bottom: @spaceLg;
    }
  }
  &.PasswordResetNewPasswordForm,
  &.SignupForm {
    .CenterOfScreenForm(450px);

    .AuthEnvelope-Title {
      margin-bottom: @spaceNorm1_5;
    }

    .ValidationSubruleResults {
      margin-bottom: @spaceNorm;
    }

    .ValidationSubruleResult {
      color: @colorGrey2;
      font-size: @font-size-lg;

      .ant-col:first-child {
        width: 24px;
      }
      .ant-col:last-child {
        .Flex1();
      }
      @{fa-selector} {
        font-size: 18px;
        &.success {
          color: @colorGreen;
        }
        &.error {
          color: @colorRed;
        }
      }
    }

    form > .ant-form-item {
      margin-bottom: @spaceLg;
    }

    form > button {
      margin-bottom: @spaceNorm1_5;
    }
  }
}

@keyframes service-alert-right-to-left-2 {
  from {
    left: 100%;
  }
  to {
    left: -100%;
  }
}
@keyframes service-alert-right-to-left-3 {
  from {
    left: 200%;
  }
  to {
    left: -100%;
  }
}
@keyframes service-alert-right-to-left-4 {
  from {
    left: 300%;
  }
  to {
    left: -100%;
  }
}

.ServiceAlerts {
  position: relative;
  width: 480px;
  margin-bottom: @spaceNorm3;

  @height: 32px;
  @overlap: 2px;
  .shared() {
    position: relative;
    height: @height;
  }
  &-Container {
    position: relative;
  }
  &-ControlButtons {
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    display: flex;
    align-self: start;
    justify-content: center;

    .responsive-md-vs-lg(
      @md-and-smaller: {
        bottom: -@spaceSm;
      },
      @lg-and-bigger: {
        bottom: 0;
      }
    );

    > * {
      .space-between(6px, horizontal);
    }
  }
  > .ServiceAlerts-ControlButtons {
    bottom: -@spaceSm;
  }
  &-Title {
    z-index: 2;
    .shared();
    color: @colorWhite;
    line-height: @height;
    padding-left: @spaceNorm2;
    font-size: @font-size-lg;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    .right-edge-skew(
      @angle: -30deg,
      @height: @height,
      @shadow: -4px 6px 15px @colorGrey7_9,
      @backgroundColor: @colorRed,
      @borderRadius: 6px 2px 2px 0,
      @marginRight: 150px
    );
  }
  &-Ticker {
    z-index: 1;
    margin-top: -@overlap;
    padding-left: @spaceNorm2;
    .shared();
    .right-edge-skew(
      @angle: -30deg,
      @height: @height,
      @shadow: 4px 6px 10px @colorGrey8_7,
      @backgroundColor: @colorWhite,
      @borderRadius: 0 2px 2px 6px,
      @paddingRight: @spaceNorm2
    );

    .SlidingContent-ContentAnimationContainer.vertical {
      height: @height;
    }
  }
  &-AlertTitle {
    position: relative;
    padding-top: @overlap;
    line-height: @height - @overlap;
    font-size: @font-size-lg;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;

    > .ServiceAlerts-AlertTitleMeasurement {
      visibility: hidden;
      display: inline-block;
    }

    > .ServiceAlerts-AlertTitleScrollContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      &.count-2 .ServiceAlerts-AlertTitleScroller {
        animation-name: service-alert-right-to-left-2;
      }
      &.count-3 .ServiceAlerts-AlertTitleScroller {
        animation-name: service-alert-right-to-left-3;
      }
      &.count-4 .ServiceAlerts-AlertTitleScroller {
        animation-name: service-alert-right-to-left-4;
      }
    }

    .ServiceAlerts-AlertTitleScroller {
      .absolute(0);
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-play-state: running;
      padding-top: @overlap;
      line-height: @height - @overlap;
      display: flex;
      align-items: center;

      &::after {
        padding: 0 @spaceNorm2_5;
        font-size: 6px;
        .fa-like('\f111');
      }
    }
  }
  &-TextContainer {
    position: relative;
    height: 100%;
  }
  &-Text {
    .absolute(0);
    font-size: @font-size-lg;
    font-style: italic;
    color: @colorGrey3_5;

    p {
      margin-bottom: 1em;
    }
  }
}

.AuthErrorDialog.SimpleConfirmDialog.SimpleDialog {
  span.ant-typography {
    line-height: 21px;
    .responsive-sm-vs-md(
      @sm-and-smaller: {
        font-size: @font-size-lg;
      },
      @md-and-bigger: {
        font-size: @heading-4-size;
      }
    );
  }

  .AuthErrorDialog__Links {
    margin-top: @spaceNorm1_5;

    line-height: 23px;
    color: @colorRed;
    text-align: center;
    font-size: @font-size-lg;
    font-weight: bold;
  }

  .ant-row {
    justify-content: center;

    > .ant-col {
      width: 110px;
      flex: initial;
    }
  }
}
