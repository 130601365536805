.apply-antd-input-styling(
  @background-color: @colorWhite,
  @border-color: @colorGrey9_5,
  @border-radius: @borderRadiusXs,
  @box-shadow: inset 0 1px 1px rgba(@colorBlack, 0.2),
  @input-height: @input-height-md,
  @text: {
    font-size: @font-size-lg;
    font-weight: 500;
  },
  @disabled-background-color: @colorGrey10,
  @view-background-color: @colorGrey9_9,
  @view-color: @text-color,
  @placeholder-color: @default-placeholder-color,
  @icon-color: @colorGreyBluish
) {
  .apply-disabled-colors() {
    background-color: @disabled-background-color;
    border-color: @border-color;
    color: @text-color;
    // Safari fix
    -webkit-text-fill-color: @text-color;
    opacity: 0.5;
  }
  .apply-view-mode-colors() {
    background-color: @view-background-color;
    border-color: @border-color;

    &,
    &::after {
      box-shadow: none !important;
    }

    &,
    input {
      color: @view-color;
      cursor: initial;
      // Safari fix
      -webkit-text-fill-color: @view-color;
      opacity: 1;
    }
  }
  .apply-colors(
    @apply-disabled: true,
    @disabled-selector: ~'&.ant-input-disabled, &.ant-input-disabled:hover, &[disabled]',
    @apply-view-mode: true,
    @view-mode-selector: ~'&.ant-input-view-mode, &.ant-input-view-mode:hover'
  ) {
    background-color: @background-color;
    border-color: @border-color;
    border-radius: @border-radius;

    &:hover,
    &:focus {
      border-color: @border-color;
    }

    .ant-form-item-has-error & {
      &,
      &:hover {
        border-color: @colorRed;
      }
    }

    .apply-disabled(false) {
    }
    .apply-disabled(true) {
      @{disabled-selector} {
        .apply-disabled-colors();
      }
    }
    .apply-disabled(@apply-disabled);

    .apply-view-mode(false) {
    }
    .apply-view-mode(true) {
      @{view-mode-selector} {
        .apply-view-mode-colors();
      }
    }
    .apply-view-mode(@apply-view-mode);
  }
  .apply-box-shadow(after) {
    position: relative;
    &::after {
      content: ' ';
      .absolute();
      box-shadow: @box-shadow;
      border-radius: @border-radius;
      pointer-events: none;
      z-index: 1;
    }
  }
  .apply-box-shadow(here) {
    box-shadow: @box-shadow;
  }

  .apply-sizing() {
    height: @input-height;
  }

  .ant-form-item,
  .ant-form-item-has-error {
    .ant-form-item-control-input-content .ant-select {
      &,
      &.ant-select-focused,
      &:hover {
        .ant-select-selector {
          .apply-colors(@apply-disabled: false, @apply-view-mode: false);
          @text();
          .apply-box-shadow(after);
          &::after {
            visibility: visible;
          }
          box-shadow: none !important;
          .apply-sizing();

          .ant-form-item-has-error & {
            border-color: @colorRed !important;
          }

          input {
            @text();
          }
        }
      }
      .ant-select-selection-placeholder {
        color: @placeholder-color;
      }
      .ant-select-arrow,
      .ant-select-clear {
        color: @icon-color;
      }
      &.ant-select-disabled .ant-select-selector {
        .apply-disabled-colors();
      }
      &.ant-input-view-mode .ant-select-selector {
        .apply-view-mode-colors();
      }
      &.ant-input-view-mode.no-background .ant-select-selector {
        background-color: inherit !important;
      }
    }
  }
  .ant-form-item-control-input-content .ant-select.ant-select-auto-complete {
    &::after,
    .ant-select-clear {
      color: @icon-color;
    }
  }
  .ant-form-item-control-input-content .ant-input-affix-wrapper {
    .apply-colors(@disabled-selector: ~'&.ant-input-affix-wrapper-disabled');
    @text();
    .apply-box-shadow(after);
    box-shadow: none !important;
    .apply-sizing();
  }
  .InputLike {
    .apply-colors();
    .apply-box-shadow(here);
    .apply-sizing();
    @text();
    display: flex;
    align-items: center;
    padding: 0 @spaceNorm;
  }
  .InputText {
    .apply-colors();

    > .ant-input {
      box-shadow: none !important;
      background-color: transparent;
    }
    &.ant-input {
      &,
      &:focus,
      .ant-form-item-has-error & {
        .apply-box-shadow(here);
      }
    }
    > .ant-input,
    &.ant-input {
      @text();
      .apply-sizing();

      &:focus,
      .ant-form-item-has-error & {
        @text();
        background-color: transparent !important;
      }

      &::placeholder {
        color: @placeholder-color;
      }
    }
  }
  .ant-input-number {
    .apply-colors(@disabled-selector: ~'&.ant-input-number-disabled, &.ant-input-number-disabled:hover, &[disabled]');
    @text();
    .apply-box-shadow(here);
    .apply-sizing();

    &:focus,
    &:hover {
      .apply-colors();
    }

    &,
    .ant-input-number-input {
      &::placeholder {
        color: @placeholder-color;
      }
    }
  }
  .ant-input-search {
    .apply-colors();
    @text();
    .apply-box-shadow(after);

    input:focus,
    input:hover {
      .apply-colors();
    }
    input {
      &::placeholder {
        color: @placeholder-color;
      }
    }
  }
  .ant-picker {
    .apply-box-shadow(after);
    box-shadow: none !important;
    .apply-sizing();
    .ant-picker-input {
      input {
        @text();

        &::placeholder {
          color: @placeholder-color;
        }
      }
    }

    &.ant-picker-focused,
    &:hover {
      .apply-colors();
    }
    .apply-colors(@disabled-selector: ~'&.ant-picker-disabled');
  }
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      color: @icon-color;

      > .ant-input-prefix,
      > .ant-input-suffix {
        &,
        .ant-form-item-has-error & {
          color: @icon-color;
        }
        .anticon.ant-input-clear-icon {
          color: @icon-color;
        }
      }
    }
  }

  .ant-transfer-list .ant-transfer-list-search {
    .ant-input {
      background-color: @background-color;
    }
  }
}

// General
.ant-form-item-control-input {
  min-height: 0;
}

// Select
.selectArrow() {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0 @spaceNorm;
  margin-top: -10px;
  width: auto;
  height: 20px;
  border-left: 1px solid currentColor;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}
.ant-form-item-control-input-content .ant-select {
  display: block;
  .ant-select-selector {
    padding-left: @spaceMd;
    padding-right: @spaceMd;

    &::after {
      width: auto;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-search {
      .absolute(0);
      padding: inherit;

      input {
        .absolute();
        height: auto;
        padding: inherit;
        padding-right: 52px;
      }
    }
  }
  .ant-select-arrow {
    .selectArrow();
  }
  &.ant-input-view-mode .ant-select-arrow {
    display: none;
  }

  &.size-sm {
    .ant-select-selector {
      height: @input-height-sm !important;
      font-size: 15px !important;
    }
    .ant-select-selection-search {
      font-size: @font-size-sm-plus;
    }
  }

  .ant-select-selection-placeholder {
    font-weight: normal;
    font-size: @font-size-base;
  }

  &.ant-input-view-mode.no-padding .ant-select-selector {
    padding: 0 !important;
  }
}

// Autocomplete
.ant-form-item-control-input-content .ant-select.ant-select-auto-complete {
  &::after {
    .fa-like('\f0d7');
    pointer-events: none;
    .selectArrow();
  }
  &.ant-input-view-mode::after {
    display: none;
  }
  &.ant-select-loading::after {
    color: transparent;
  }
  .ant-select-arrow-loading {
    border-width: 0;
  }
}

.ant-form-item-control-input-content {
  .ant-select.ant-select-auto-complete,
  .ant-select {
    .ant-select-clear {
      right: @spaceXl;
      background-color: transparent;
    }
  }
}

// Suffix
.form-item-suffix {
  .absolute-middle-next-to-right();

  img {
    height: 16px;
    width: auto;
  }
}

// Password
.ant-form-item-control-input-content {
  .ant-input-password.ant-input-affix-wrapper {
    display: inline-block;
    padding: 0;

    &::before {
      display: none;
    }

    .ant-input {
      height: 100%;
    }

    .ant-input-suffix {
      margin: 0;
      width: initial;
      .form-item-suffix();
    }

    .ant-input-password-icon {
      display: flex;
      align-items: center;
      color: @colorRed;
      font-size: @font-size-sm-plus;

      &.anticon::after {
        margin-left: @spaceXs;
      }
      &.anticon-eye-invisible::after {
        content: ' Show';
      }
      &.anticon-eye::after {
        content: ' Hide';
      }
    }
  }
}

// Text input
.InputText {
  & > .ant-input,
  &.ant-input {
    font-size: @font-size-lg;
    padding-left: @spaceMd;
    padding-right: @spaceMd;

    &::placeholder {
      font-weight: normal;
    }
  }
}

// Numeric input
.ant-input-number {
  width: 100%;
  font-size: @font-size-lg;

  &,
  .ant-input-number-input {
    height: 100%;

    &::placeholder {
      font-weight: normal;
    }
  }
}

// Search box
.ant-input-search {
  height: @input-height-sm;

  input {
    font-size: @font-size-sm;
    line-height: @input-height-sm - 2;
    padding: 0 @spaceSm;
    &,
    &:focus {
      box-shadow: none;
    }
  }

  .ant-btn {
    width: @input-height-sm;
    height: @input-height-sm;
    padding: 0;
    font-size: 21px;
    .flex-center();
  }
}

// Checkable common
.checkable-theme(@suffix, @body) {
  &,
  &:hover,
  &:focus {
    &.theme-@{suffix} {
      @body();
    }
  }
}

@checkableThemes: {
  bluish: @colorGreyBluish;
};

.override-ant-checkbox-theme(@value, @secondary-value: @colorWhite8) {
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: @value;
  }
  .ant-checkbox .ant-checkbox-inner {
    border-color: @value;
    background-color: @secondary-value;
    &::before {
      color: @secondary-value;
    }
  }
}

// Checkbox
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;

  each(@checkableThemes, {
    .checkable-theme(@key, {
      .override-ant-checkbox-theme(@value);
    });
  });
  .ant-checkbox {
    top: 0;
  }
  &.vertical {
    flex-direction: column;
    gap: @spaceXxs;

    .ant-checkbox {
      margin-top: 0;
      & + span {
        padding-left: 0;
      }
    }
  }
  &.vertical-reverse {
    flex-direction: column-reverse;
    gap: @spaceXxs;

    .ant-checkbox {
      margin-top: 0;
      & + span {
        padding-left: 0;
      }
    }
  }

  > * {
    display: block;
  }

  &::after {
    display: none;
  }
}
.ant-checkbox {
  & + span {
    padding-left: @spaceXs;
    padding-right: 0;
  }
}
.ant-checkbox-checked {
  &::after {
    display: none;
  }
}
.ant-checkbox-inner {
  .ant-form-item-has-error .ant-checkbox-wrapper & {
    &,
    &:hover {
      border-color: @colorRed;
    }
  }

  border-radius: @borderRadiusXs;

  .ant-checkbox & {
    background-color: @colorWhite8;
    border: 1px solid @colorGreyBluish;
  }

  .ant-checkbox-checked & {
    background-color: @colorRed;
    border-color: @colorRed;

    &::after {
      display: none;
    }
    &::before {
      .fa-like('\f00c');
      color: @colorWhite;
      .absolute-middle-center;
      font-size: 0.68em;
      margin: 1px 0 0 0;
    }
  }
}

.override-ant-radio-theme(@value, @secondary-value: @colorWhite) {
  .ant-radio-inner {
    border-color: @value;
    background-color: @secondary-value;
  }
  .ant-radio-checked .ant-radio-inner {
    color: @value;
    &::after {
      background-color: @value;
    }
  }
}

// Radio
.ant-radio-wrapper {
  .flex-center();
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  each(@checkableThemes, {
    .checkable-theme(@key, {
      .override-ant-radio-theme(@value);
    });
  });
  &.ant-radio-wrapper-disabled {
    cursor: not-allowed;
  }

  > * {
    display: block;
    position: initial;
  }

  &::after {
    display: none;
  }
}
.ant-radio {
  & + span {
    font-weight: 500;
    padding-left: 0;
    padding-right: @spaceXs;
  }
  &.ant-radio-checked::after {
    display: none;
  }
}
.ant-radio-inner {
  border-color: @colorGreyBluish;

  .ant-radio-input:focus + & {
    box-shadow: none;
  }

  .ant-radio-checked & {
    &::before {
      background-color: @colorRed;
    }
  }
}

// Date-, time- picker
.ant-picker {
  padding: 0;
  .ant-picker-input {
    height: 100%;
    input {
      line-height: 1;
      padding: 0;
      padding-left: @spaceSm;
      padding-top: 1px; // For visual centering effect
      &::placeholder {
        font-weight: normal;
      }
    }
  }
  .DatePreviewIcon {
    color: @colorRed;
  }
  .ant-picker-suffix {
    background-color: transparent;
  }
  .ant-picker-clear {
    background-color: transparent;
  }

  &.suffix-as-prefix {
    .ant-picker-input {
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-left: @spaceNorm;
        margin-right: @spaceNorm - @spaceSm;
        @media (max-width: @screen-xs-max) {
          margin-left: @spaceNorm0_5;
          margin-right: @spaceNorm0_5 - @spaceSm;
        }
        > * {
          display: block;
          margin-right: 0 !important;
        }
      }
    }
  }

  &.ant-picker--special-value {
    > .ant-picker-input > input::placeholder {
      color: @colorGrey3;
      font-weight: 500;
    }
  }

  &.ant-picker-disabled {
    .ant-picker-input {
      @{fa-selector} {
        color: @colorGrey8_2;
      }
      input {
        &,
        &::placeholder {
          color: @colorGrey8_2;
        }
      }
    }
  }
}
.ant-picker-dropdown {
  .ant-picker-ranges {
    line-height: 26px;
  }
  .ant-picker-now-btn {
    color: @colorRed;
  }
  .ant-picker-footer-extra {
    padding: 0;
  }
}

// Affix wrapper
.ant-form-item-control-input-content {
  // General
  .ant-input-affix-wrapper {
    align-items: center;
    padding: 0;

    &:hover,
    &:focus,
    &.ant-input-affix-wrapper-focused {
      z-index: initial;
    }

    > .ant-input {
      height: 100%;
    }

    > .ant-input-prefix,
    > .ant-input-suffix {
      margin: 0 @spaceNorm;
    }
  }

  // With floating label
  .FloatingLabelWrapper .ant-input-affix-wrapper {
    > .ant-input-prefix {
      margin-left: 0;
      margin-right: 0;
      width: @spaceNorm5;
      height: 100%;
      font-size: 14px;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-right: 1px solid @colorGrey9_5;
      margin-right: 5px;
    }
    > .ant-input-suffix {
      margin-left: 0;
      text-align: right;
    }
  }
}

// Form item
.ant-form-item {
  &,
  &.ant-form-item-with-help {
    margin-bottom: @spaceSm;
  }
}

.ant-form-item-label {
  .ant-form-inline .ant-form-item > & {
    display: flex;
    align-items: center;

    > label {
      height: auto;
    }
  }
  > label {
    height: @input-height-md;
    font-weight: 500;
  }
  > label::before {
    display: none !important;
    content: '' !important;
  }
  > label::after {
    .text-after(':');
  }
  > label.ant-form-item-no-colon::after {
    content: '';
  }
  > label.ant-form-item-no-colon.ant-form-item-required::after {
    content: '*';
  }
  > label.ant-form-item-required::after {
    content: ': *';
  }
}

// Validation message
.ant-form-item-explain {
  height: 0;
  min-height: 0;
  margin: 0;
  padding-top: @spaceXxs;
  margin-bottom: -@spaceXxs;
  overflow: visible;
  line-height: 1;
  font-size: @font-size-sm-plus;
  .nl-as-newline();

  .height-extending-error & {
    height: auto;
    margin-bottom: @spaceXs;
  }

  .hidden-error & {
    display: none;
  }
}

// Info message
.ant-form-item-extra {
  margin-top: 0;
  text-align: right;
}

// Responsive
.ant-form-inline .ant-form-item {
  @media (max-width: @screen-xs-max) {
    flex-wrap: nowrap;

    .ant-form-item-label {
      flex: none;
      display: inline-flex;
      align-items: center;
      margin-right: @spaceSm;
      padding-bottom: 0;
    }
    .ant-form-item-control {
      display: inline-block;
      flex: none;
    }
  }
}

.ant-popover-inner-content {
  width: initial;
}
