@import (css)
  url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');
@import (css)
  url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import (css)
  url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
@import (css)
  url('https://fonts.googleapis.com/css2?family=Oxygen:wght@700&display=swap');
@import (css)
  url('https://fonts.googleapis.com/css?family=Inter:500,600,700&display=swap');

@font-face {
  font-family: 'League Gothic';
  src: url('../resources/fonts/LeagueGothic-Regular.woff2'),
    url('../resources/fonts/LeagueGothic-Regular.woff'),
    url('../resources/fonts/LeagueGothic-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
