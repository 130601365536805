.AdminAddUser__Steps.ant-steps.ant-steps-vertical {
  &.AdminAddUser__Steps--Level1 {
    .ant-steps-item {
      > .ant-steps-item-container {
        padding-left: @spaceNorm3;
      }
      > .ant-steps-item-container > .ant-steps-item-tail {
        left: 27px;
      }
    }
  }
  &.AdminAddUser__Steps--Level2 {
    .ant-steps-item {
      > .ant-steps-item-container {
        padding-left: @spaceXl;
      }
      > .ant-steps-item-container > .ant-steps-item-tail {
        left: 47px;
      }
    }
  }
  &.AdminAddUser__Steps--Level3 {
    .ant-steps-item {
      > .ant-steps-item-container {
        padding-left: @spaceNorm5;
      }
      > .ant-steps-item-container > .ant-steps-item-tail {
        left: 47px;
      }
    }
  }

  .ant-steps-item {
    > .ant-steps-item-container {
      display: flex;
      align-items: flex-start;
      position: relative;
    }

    &.ant-steps-item-finish,
    &.ant-steps-item-wait {
      &,
      &:hover,
      &:active {
        color: @colorGreyBluish3_2;
      }
    }

    &.ant-steps-item-active {
      &,
      &:hover,
      &:active {
        color: @colorGreyBluish7_4;
      }
    }

    &.ant-steps-item-finish,
    &.ant-steps-item-wait,
    &.ant-steps-item-active {
      &.Clickable:hover {
        color: @colorGreyBluish7_4;
      }
    }

    .ant-steps-item-icon {
      &,
      & > .ant-steps-icon {
        color: inherit !important;
      }
    }

    .ant-steps-item-content {
      &,
      & > .ant-steps-item-title {
        color: inherit !important;
      }
    }

    > .ant-steps-item-container > .ant-steps-item-tail {
      top: 0;
      padding: 19px 0 5px;

      &::after {
        background-color: @colorGreyBluish3_2;
      }
    }

    .ant-steps-item-icon {
      margin-top: -2px;
      margin-right: @spaceMd;
      position: initial;
      width: auto;
      height: auto;
    }

    .ant-steps-item-content {
      min-height: 40px;
      height: auto;
      overflow: visible;
    }

    .ant-steps-item-title {
      font-size: @font-size-base;
      line-height: 1;
    }
  }
}
