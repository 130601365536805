.hide() {
  display: none !important;
}

.unhide() {
  div& {
    display: block !important;
  }
  span& {
    display: inline !important;
  }
  &.ant-row {
    display: flex !important;
  }
}

.hide-xl-and-smaller {
  @media (max-width: @screen-xl-max) {
    .hide();
  }

  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .hide();
  }
  .responsive-force-xxl & {
    .unhide();
  }
}
.hide-lg-and-smaller {
  @media (max-width: @screen-lg-max) {
    .hide();
  }

  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl & {
    .unhide();
  }
}
.hide-md-and-smaller {
  @media (max-width: @screen-md-max) {
    .hide();
  }

  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg & {
    .unhide();
  }
}
.hide-sm-and-smaller {
  @media (max-width: @screen-sm-max) {
    .hide();
  }

  .responsive-force-sm &,
  .responsive-force-xs & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md & {
    .unhide();
  }
}
.hide-xs-and-smaller {
  @media (max-width: @screen-xs-max) {
    .hide();
  }

  .responsive-force-xs & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm & {
    .unhide();
  }
}

.hide-xl-and-bigger {
  @media (min-width: @screen-xl-min) {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl & {
    .hide();
  }
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-lg-and-bigger {
  @media (min-width: @screen-lg-min) {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg & {
    .hide();
  }
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-md-and-bigger {
  @media (min-width: @screen-md-min) {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md & {
    .hide();
  }
  .responsive-force-sm &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-sm-and-bigger {
  @media (min-width: @screen-sm-min) {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm & {
    .hide();
  }
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-xs-and-bigger {
  @media (min-width: @screen-xs-min) {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .hide();
  }
}

.hide-xxl {
  @media (min-width: @screen-xxl-min) {
    .hide();
  }
  .responsive-force-xxl & {
    .hide();
  }
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-xl {
  @media (min-width: @screen-xl-min) and (max-width: @screen-xl-max) {
    .hide();
  }
  .responsive-force-xl & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-lg {
  @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
    .hide();
  }
  .responsive-force-lg & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-md {
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .hide();
  }
  .responsive-force-md & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-sm {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .hide();
  }
  .responsive-force-sm & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-xs & {
    .unhide();
  }
}
.hide-xs {
  @media (max-width: @screen-xs-max) {
    .hide();
  }
  .responsive-force-xs & {
    .hide();
  }
  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm & {
    .unhide();
  }
}

.responsive-xs-vs-sm(@xs, @sm-and-bigger) {
  @media (max-width: @screen-xs-max) {
    @xs();
  }
  @media (min-width: @screen-sm-min) {
    @sm-and-bigger();
  }

  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm & {
    @sm-and-bigger();
  }

  .responsive-force-xs & {
    @xs();
  }
}

.responsive-sm-vs-md(@sm-and-smaller, @md-and-bigger) {
  @media (min-width: @screen-md-min) {
    @md-and-bigger();
  }
  @media (max-width: @screen-sm-max) {
    @sm-and-smaller();
  }

  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg &,
  .responsive-force-md & {
    @md-and-bigger();
  }

  .responsive-force-sm &,
  .responsive-force-xs & {
    @sm-and-smaller();
  }
}

.responsive-md-vs-lg(@md-and-smaller, @lg-and-bigger) {
  @media (min-width: @screen-lg-min) {
    @lg-and-bigger();
  }
  @media (max-width: @screen-md-max) {
    @md-and-smaller();
  }

  .responsive-force-xxl &,
  .responsive-force-xl &,
  .responsive-force-lg & {
    @lg-and-bigger();
  }

  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    @md-and-smaller();
  }
}

.responsive-lg-vs-xl(@lg-and-smaller, @xl-and-bigger) {
  @media (min-width: @screen-xl-min) {
    @xl-and-bigger();
  }
  @media (max-width: @screen-lg-max) {
    @lg-and-smaller();
  }

  .responsive-force-xxl &,
  .responsive-force-xl & {
    @xl-and-bigger();
  }

  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    @lg-and-smaller();
  }
}

.responsive-md-vs-lg-vs-xl(@md-and-smaller, @lg, @xl-and-bigger) {
  @media (min-width: @screen-xl-min) {
    @xl-and-bigger();
  }
  @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
    @lg();
  }
  @media (max-width: @screen-md-max) {
    @md-and-smaller();
  }

  .responsive-force-xxl &,
  .responsive-force-xl & {
    @xl-and-bigger();
  }

  .responsive-force-lg & {
    @lg();
  }

  .responsive-force-md &,
  .responsive-force-sm &,
  .responsive-force-xs & {
    @md-and-smaller();
  }
}

.responsive-xs-vs-sm-vs-xl(@xs, @sm-to-lg, @xl-and-bigger) {
  @media (min-width: @screen-xl-min) {
    @xl-and-bigger();
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-lg-max) {
    @sm-to-lg();
  }
  @media (max-width: @screen-xs-max) {
    @xs();
  }

  .responsive-force-xxl &,
  .responsive-force-xl & {
    @xl-and-bigger();
  }

  .responsive-force-lg &,
  .responsive-force-md &,
  .responsive-force-sm & {
    @sm-to-lg();
  }

  .responsive-force-xs & {
    @xs();
  }
}
