.link-device-to-job-page {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .TwoLines {
    display: none;
  }

  button {
    width: 100%;
    height: 40px;
    margin-top: 24px;
  }

  > video {
    height: calc(100% - 373px);
  }

  .ant-select {
    width: 100%;
  }
}
