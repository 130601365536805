.EmailNotifications {
  @{fa-selector} {
    color: @colorRed;
    &.EmailNotifications__OverrideSwitch--On {
      color: @colorRed2;
    }
    &.EmailNotifications__OverrideSwitch--Off {
      color: @colorGrey3;
      transform: rotateZ(180deg);
    }
  }
  .ant-btn.ant-btn-primary @{fa-selector} {
    color: @colorWhite;
  }

  form {
    margin-bottom: 0;
  }

  .TwoLines + .TwoLines {
    margin-top: @spaceXxs;
  }

  .ant-checkbox-wrapper {
    flex-direction: column-reverse;
    gap: @spaceXxs;
  }

  .EmailNotifications__AddNotificationButton {
    height: 32px;
    border-radius: @borderRadiusSm;
    border: 1px solid @colorRed;
    background-color: @colorWhite;
    padding: 0 @spaceNorm;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: @font-size-lg;
    letter-spacing: -0.18px;
    color: @colorRed;
  }

  .EmailNotifications__ActiveNotifications__Title {
    font-family: @fontCondensed;
    font-weight: bold;
    font-size: @font-size-lg;
    text-transform: uppercase;
    color: @colorGreyBluish5;
    line-height: 1;
  }

  .EmailNotifications__ActiveNotifications__None {
    font-weight: 500;
    font-size: @font-size-lg;
    color: @colorGrey6;
    line-height: 1;
    font-style: italic;
    margin-top: -@spaceXxs;
  }

  .EmailNotifications__BooleanPreview.ant-tag {
    display: flex;
    background-color: @colorGreyBluish2;
    border-color: @colorGreyBluish2;
    border-radius: @borderRadiusLg;
    height: 20px;
    color: @colorWhite;
    font-family: @fontStd;
    font-size: @font-size-sm;
    font-weight: 500;
    margin-right: 0;

    @{fa-selector} {
      color: @colorWhite;
      margin-right: @spaceXs;
    }
  }

  .EmailNotifications__OtherEmailsControls {
    display: flex;
    flex-direction: column;
    gap: @spaceSm;
  }

  .EmailNotifications__OtherEmailsLabel {
    font-size: @font-size-sm;
    color: @colorGreyBluish;
  }
  .EmailNotifications__OtherEmailsValue {
    font-size: @font-size-lg;
    color: @colorGrey3_5;
    line-height: 1.125;
  }
}

.EmailNotifications__Card {
  border-radius: @borderRadiusSm;
  box-shadow: 1px 1px 6px @colorGrey8_1;
}

.EmailNotifications__Card__Header {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 @spaceNorm;
  font-size: @font-size-lg;
  letter-spacing: -0.18px;
  color: @colorGrey4_5;
  .border-radius(@borderRadiusSm, top);
  border: 1px solid @colorGrey8_7;
}
.EmailNotifications__Card__Header--Dark {
  justify-content: space-between;
  color: @colorWhite;
  background-image: linear-gradient(
    180deg,
    @colorGreyDark0_5,
    @colorGreyDark0_7
  );
  font-family: @fontStd;
  font-weight: 500;

  @{fa-selector} {
    color: @colorWhite;
    display: block;
  }
}
.EmailNotifications__Card__Header--Light {
  gap: @spaceSm;
  background-color: @colorWhite2;
  font-family: @fontCondensed;
  font-weight: bold;
}

.EmailNotifications__Card__Body {
  padding: @spaceNorm2 @spaceNorm;
  background-color: @colorWhite;
  .border-radius(@borderRadiusSm, bottom);
}

.EmailNotifications__Subform {
  padding: 0 @spaceNorm;
}
.EmailNotifications__Subform--Disabled {
  opacity: 0.5;
  pointer-events: none;
}

.EmailNotifications__OverrideLabel {
  font-family: @fontCondensed;
  font-weight: bold;
  font-size: @font-size-lg;
}

.EmailNotifications__OverrideValue {
  font-family: @fontCondensed;
  font-weight: bold;
  font-size: @font-size-base;
  text-transform: uppercase;

  label {
    display: flex;
    align-items: center;
    gap: @spaceXs;
  }
}
