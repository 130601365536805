.FormTheme--Default {
  .apply-antd-input-styling();
}
.FormTheme--Default();

.FormTheme--DarkCommon() {
  .FloatingLabelWrapper > .ant-input-view-mode + label {
    color: @colorGreyBluish3;
    opacity: 1;
  }

  .ant-checkbox-wrapper {
    .override-ant-checkbox-theme(@colorGreyBluish8, @colorGreyDark2_7);
    color: @colorGreyBluish8;
    font-size: @font-size-lg-minus;
    font-weight: 500;

    &.ant-checkbox-wrapper-checked {
      color: @colorOrange1;
    }

    .ant-checkbox-disabled {
      opacity: 0.5;
    }
    .ant-checkbox-disabled + span {
      color: inherit;
      opacity: 0.5;
    }
  }
  .ant-radio-wrapper {
    .override-ant-radio-theme(@colorGreyBluish8, @colorGreyDark2_7);
    color: @colorGreyBluish8;
    font-size: @font-size-lg-minus;
    font-weight: 500;

    &.ant-radio-wrapper-checked {
      color: @colorOrange1;
    }
  }
}

.FormTheme--DarkSimple {
  .apply-antd-input-styling(
    @background-color: @colorGreyDark1_1,
    @border-color: @colorGreyDark5,
    @border-radius: @borderRadiusXs,
    @box-shadow: none,
    @input-height: @input-height-md,
    @text: {
      font-size: @font-size-lg;
      font-weight: 500;
      color: @colorGrey7_1;
    },
    @disabled-background-color: @colorGreyDark2_6,
    @view-background-color: @colorGreyDark2,
    @view-color: @colorGrey7_1,
    @placeholder-color: rgba(@colorGrey7_1, 0.5),
    @icon-color: @colorGreyBluish2
  );
  .FormTheme--DarkCommon();
}

.FormTheme--DarkOrange {
  .apply-antd-input-styling(
    @background-color: @colorGreyDark1_1,
    @border-color: @colorGreyDark2_7,
    @border-radius: @borderRadiusXs,
    @box-shadow: inset 0 2px 5px rgba(@colorBlack0_7, 0.5),
    @input-height: @input-height-md,
    @text: {
      font-size: @font-size-lg;
      font-weight: 500;
      color: @colorOrange1;
    },
    @disabled-background-color: @colorGreyDark2_6,
    @view-background-color: @colorGreyDark2,
    @view-color: @colorOrange1,
    @placeholder-color: rgba(@colorOrange1, 0.5),
    @icon-color: @colorGreyBluish2
  );
  .FormTheme--DarkCommon();
}

.FormTheme--DarkOrangeSimple {
  .apply-antd-input-styling(
    @background-color: @colorGreyDark1_1,
    @border-color: @colorGreyDark2_7,
    @border-radius: @borderRadiusXs,
    @box-shadow: none,
    @input-height: @input-height-md,
    @text: {
      font-size: @font-size-lg;
      font-weight: 500;
      color: @colorOrange1;
    },
    @disabled-background-color: @colorGreyDark2_6,
    @view-background-color: @colorGreyDark2,
    @view-color: @colorOrange1,
    @placeholder-color: rgba(@colorOrange1, 0.5),
    @icon-color: @colorGreyBluish2
  );
  .FormTheme--DarkCommon();
}

.ButtonTheme--DarkSimple {
  @{btn-default-selector}.ant-btn {
    font-size: @font-size-lg-minus;
    box-shadow: none;

    &,
    &:hover,
    &:active,
    &:focus {
      background-color: @colorGreyDark1_1;
      border-color: @colorGreyDark5;
      color: @colorGreyBluish;
    }

    .def-btn-disabled({
      opacity: 0.3;
    });
  }

  .ant-btn.ant-btn-primary {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: @colorBlue3;
      border-width: 0;
      color: @colorWhite;
    }

    .def-btn-disabled({
      opacity: 0.53;
    });
  }

  .ant-btn.ant-btn-background-ghost {
    &,
    &:hover,
    &:active,
    &:focus {
      border-color: @colorGrey7_1;
      color: @colorGrey7_1;
    }

    @{fa-selector} {
      color: @colorGreyBluish7_4;
    }

    .def-btn-disabled({
      opacity: 0.3;
    });
  }

  .ant-btn.ant-btn-sm {
    font-size: @font-size-base;
    font-family: @font3;
    font-weight: bold;
  }
  .ant-btn:not(.ant-btn-sm):not(.ant-btn-lg) {
    font-size: @font-size-lg;
  }
}

.AntGeneralTheme--DarkSimple {
  .ant-switch {
    width: 34px;
    min-width: 34px;
    height: 22px;
    background-color: @colorGreyBluish7_4;
  }
  .ant-switch-handle {
    &::before,
    &::after {
      background-color: @colorGreyDark2_5;
      .absolute(1px);
      border-radius: 50%;
    }
  }
}

.FormTheme--DarkSimple,
.FormTheme--DarkOrange {
  &.ant-select-dropdown {
    background-color: @colorGreyDark5;
    box-shadow: 0 1px 60px @colorBlack0_7;

    .ant-select-item {
      color: @colorGreyBluish4;

      &:hover {
        .dark-menu-item-hover();
      }
      &.ant-select-item-option-selected,
      &.ant-select-item-option-active {
        .dark-menu-item-active();
      }
    }
  }
}
