.BaseLoggedPage {
  .Page();
  background-color: @colorWhite2;

  &-Content {
    padding: @spaceNorm;
    padding-top: 0;

    @media (max-width: @screen-sm-max) {
      padding-top: @spaceNorm;
    }
  }
  &-InnerContent {
    margin: @spaceNorm;
    @media (max-width: @screen-sm-max) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .ant-typography {
    color: @colorGrey3;
  }
}

.DetailPage {
  .Page();
  background-color: @colorWhite3;

  &-Content {
    padding: @spaceNorm;
  }
  &-InnerContent {
    margin: @spaceNorm2 @spaceNorm;
    @media (max-width: @screen-md-max) {
      margin: @spaceNorm2 0;
    }
  }

  .ant-typography {
    color: @colorGrey3;
  }
}

.ModalPage {
  .Page();

  &-Content {
    padding: @spaceNorm3 100px;
    @media (max-width: @screen-md-max) {
      padding: @spaceNorm3 @spaceNorm;
    }
  }
  &-InnerContent {
    margin: @spaceNorm2 0;
    @media (max-width: @screen-md-max) {
      margin: @spaceNorm 0;
    }
  }
}

.NonLoggedPage {
  .Page();

  &-Content {
    padding: 0 @spaceNorm2 @spaceNorm @spaceNorm2;
    @media (max-width: @screen-lg-max) {
      padding: @spaceNorm;
    }
  }
  &-InnerContent {
    margin: @spaceNorm 0;
    @media (min-width: @screen-md-min) and (max-width: @screen-lg-max) {
      justify-content: flex-start;
      margin: @spaceNorm3 @spaceNorm;
    }
    @media (max-width: @screen-sm-max) {
      justify-content: flex-start;
      margin: @spaceNorm2 @spaceNorm;
    }
  }

  &::before {
    content: ' ';
    .absolute(0);
    opacity: 0.65;
    background-image: url('../resources/img/abstract-background.jpg');
    background-size: cover;
    background-position: top center;
    background-color: @colorWhite3;
  }
}
