.ant-modal.ChangelogDialog {
  margin-top: 32px;

  @media (min-width: @screen-lg-min) {
    width: 800px !important;
  }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    width: 500px !important;
  }
  @media (max-width: @screen-sm-max) {
    width: 320px !important;
  }

  .outside-shape() {
    background-color: @colorWhite3;
    border: 2px solid @colorWhite;
    border-radius: @borderRadiusLg;
  }

  .ant-modal-content {
    .outside-shape();
    filter: drop-shadow(1px 6px 30px rgba(@colorGrey6, 0.8));
    box-shadow: none;
  }

  .ant-modal-header {
    .outside-shape();
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-width: 0;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    height: 40px;

    .responsive-sm-vs-md(
      @sm-and-smaller: {
        width: 280px;
      },
      @md-and-bigger: {
        width: 320px;
      }
    );

    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @heading-4-size;
      font-weight: bold;
      color: @colorGrey3_5;

      .QuickOnlineLogoQ {
        margin-right: @spaceMd;
      }
    }
  }

  .ant-modal-body {
    height: 736px;
    max-height: 80vh;
    padding: @spaceNorm 0;
    display: flex;
    flex-direction: column;
  }

  .ChangelogDialog-ControlButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: @spaceSm @spaceNorm1_5;

    > .ant-btn {
      .space-between(@spaceNorm, horizontal);
    }
  }

  .ChangelogDialog-Content {
    padding-top: @spaceNorm2;
    color: @colorGreyDark2;
    font-size: @font-size-lg;
    display: flex;
    flex-direction: column;

    .responsive-md-vs-lg(
      @md-and-smaller: {
        padding-left: @spaceNorm;
        padding-right: @spaceNorm;
      },
      @lg-and-bigger: {
        padding-left: @spaceXl;
        padding-right: @spaceXl;
      }
    );

    a {
      &,
      &:link,
      &:visited {
        color: @colorBlue2;
        text-decoration: underline;
      }
    }

    h1 {
      height: 32px;
      line-height: 29px;
      text-align: center;
      border-top: 2px solid @colorWhite;
      border-bottom: 1px solid rgba(@colorGreyBluish7, 0.35);
      font-size: @heading-4-size;
      font-weight: bold;
      color: @colorRed;
      text-transform: uppercase;

      .responsive-md-vs-lg(
        @md-and-smaller: {
          margin-left: -@spaceNorm;
          margin-right: -@spaceNorm;
        },
        @lg-and-bigger: {
          margin-left: -@spaceXl;
          margin-right: -@spaceXl;
        }
      );
    }

    h2,
    h3 {
      font-size: @heading-3_5-size;
      line-height: 1;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: @spaceNorm;
    }

    ul,
    ol {
      margin-bottom: @spaceNorm1_5;
      padding-left: 16px + @spaceNorm;
    }

    li {
      padding-left: @spaceNorm2 - 12px;
    }

    img {
      max-width: 100%;
      margin: auto;
    }
  }
}
