.CopyToClipboard-HoverArea {
  &--AlwaysVisible {
    .CopyToClipboard-Icon {
      visibility: visible;
    }
  }
  &--ShowOnHover {
    &:hover {
      .CopyToClipboard-Icon {
        visibility: visible;
      }
    }

    .CopyToClipboard-Icon {
      @media (hover: hover) {
        visibility: hidden;
      }
      @media (hover: none) {
        visibility: visible;
      }
    }
  }
}

.CopyToClipboard-Icon {
  > * {
    padding: 0 @spaceSm;
  }
}
