.NewShipment-SelectAccountDialog.ant-modal.SimpleDialog {
  .SimpleDialog-Icon {
    > img {
      width: 54px;
      height: 57px;
    }
    &::before {
      top: 4px;
      left: 11px;
      right: 1px;
      bottom: 14px;
    }
  }
  .ant-modal-body {
    padding: @spaceXl @spaceNorm2_5 @spaceNorm1_5 @spaceNorm2_5;
  }
  h1.ant-typography {
    @media (max-width: @screen-sm-max) {
      font-size: @heading-2-size;
    }
  }
}

.OrderSuccessDialog {
  .ant-btn {
    width: 100%;
    border: 1px solid @colorWhite;
    box-shadow: 0 4px 20px rgba(@colorGreyBluish, 0.85);

    &.ant-btn-lg {
      font-size: @heading-4-size;
      font-weight: 500;
    }
  }
}
