.ProcessingModal {
  .QuickOnlineLogo {
    @media (min-width: @screen-md-min) {
      width: 220px;
      height: 64px;
    }
    @media (max-width: @screen-sm-max) {
      width: 200px;
      height: 58px;
    }
  }

  > img {
    margin: @spaceNorm2 0;
    @media (min-width: @screen-md-min) {
      width: 301px;
      height: 307px;
    }
    @media (max-width: @screen-sm-max) {
      width: 260px;
      height: 265px;
    }
  }

  h1.ant-typography {
    color: @colorGrey3;
    font-weight: bold;
    font-family: @fontCondensed;
    margin-bottom: 0;
    @media (min-width: @screen-md-min) {
      font-size: @heading-1_5-size;
    }
    @media (max-width: @screen-sm-max) {
      font-size: @heading-2-size;
    }
  }

  .animated-fade-in-out {
    animation-name: antFadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-play-state: running;
  }
}
