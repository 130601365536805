.AdminConsoleTemplate {
  .Page();
  background-color: @colorBlack2;
  z-index: 1;

  &-Content {
    min-width: 1024px;
  }

  &-InnerContent {
    padding-top: @spaceNorm2;
  }

  // Theme overrides
  .ButtonTheme--DarkSimple();
  .AntGeneralTheme--DarkSimple();
}

.AdminConsoleTemplate--Scrollable {
  @media (min-width: @screen-md-min) {
    padding-right: @spaceNorm2;
  }
}

.AdminConsoleHeader {
  position: relative;
  padding: 0 @spaceNorm;
  height: 40px;

  > .ant-row {
    height: 100%;
  }
}

.AdminConsoleHeader__Left {
  font-family: @font6;
  font-size: @font-size-20;
  color: @colorGreyBluish8;
  line-height: 1;
  display: flex;
}

.AdminConsoleHeader__Right {
  color: @colorGreyBluish7_4;

  .IconButton {
    .IconButton-Icon {
      transform: scaleX(-1);
    }
    .IconButton-Label {
      font-size: @font-size-sm-minus;
      font-weight: bold;
      font-family: @fontCondensed;
    }
  }
}

.AdminConsoleHeader__Version {
  position: absolute;
  top: 100%;
  margin-top: @spaceXxs;
  left: @spaceNorm;
  z-index: 1;
  font-size: @font-size-xs;
  font-weight: 500;
  color: @colorGrey6;
}

.AdminConsoleMenu {
  border-top: 1px solid @colorGreyDark3;
  display: flex;
  justify-content: center;
}

.AdminConsoleMenu__Content {
  display: flex;
  align-items: center;
  box-shadow: 0 10px 15px rgba(@colorBlack1, 0.5);
  .border-radius(@borderRadius15, bottom);
}

.AdminConsoleMenu__Item {
  width: 150px;
  height: 30px;
  display: flex;
  background-color: @colorGreyDark1_3;
  border: 1px solid @colorGreyDark3;
  border-top-width: 0;
  color: @colorGreyBluish3_2;
  letter-spacing: 0.23px;

  &:first-child {
    border-bottom-left-radius: @borderRadius15;
  }
  &:last-child {
    border-bottom-right-radius: @borderRadius15;
  }

  &:hover {
    background-color: @colorGreyDark2;
    color: @colorGreyBluish7_4;
  }
  &.active,
  &:active {
    background-color: @colorGreyDark3;
    color: @colorGreyBluish7_4;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    > * {
      pointer-events: none;
    }
  }
}

.AdminConsoleMenu__ItemInner {
  &,
  a&,
  a&:link {
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @font-size-lg-minus;
    font-weight: bold;
    font-family: @fontCondensed;
    color: inherit;
  }
}
