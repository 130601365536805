#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.Page() {
  .absolute(0);

  &-Content {
    width: 100%;
    height: 100%;
    min-width: @screen-xs-min;
    display: flex;
    flex-direction: column;
  }

  &-InnerContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.PDFViewer {
  flex: 1;
  width: 100%;
}
